import * as types from '../actions/actionTypes';

const initialState = {
    user: {},
    loading: false,
    error: null,
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LOGIN_START:
            return {
                ...state,
                loading: true
            };
        case types.LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload
            };
        case types.LOGIN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case types.REGISTER_START:
            return {
                ...state,
                loading: true
            };
        case types.REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload
            };
        case types.REGISTER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case types.LOGOUT_USER:
            return {
                ...state,
                user: {},
                loading: false,
                error: null,
                linkedAccounts: null
            };
        case types.SET_ERROR_EMPTY:
            return {
                ...state,
                error: null
            };
        
        default:
            return state;
    }
}
