import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import axios from "axios";

const TermsAndConsitionsPage = () => {
  const [updatedDate, setUpdatedDate] = useState("");
  const [updatedContent, setUpdatedContent] = useState("");

  useEffect(() => {
    fetchContent();
  });

  const fetchContent = (async) => {
    const config = {
      header: {
        Accept: "application/json",
      },
    };

    try {
      const response = axios.get("/api/v2/privacy-policy", config);
    } catch (error) {
      console.error(error);
    }
  };

  const content = `


<h2><span style="font-size:22.5pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">Introduction</span></span></span></h2>


<p><span style="font-size:12pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">These Website Standard Terms and Conditions written on this webpage shall manage your use of our website, GeePay accessible at https://geepay.co.zm.</span></span></span></p>


<p><span style="font-size:12pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">These Terms will be applied fully and affect to your use of this Website. By using this Website, you agreed to accept all terms and conditions written in here. You must not use this Website if you disagree with any of these Website Standard Terms and Conditions<span style="font-size:12pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">.</span></span></span></p>


<p><span style="font-size:12pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">Minors or people below 18 years old are not allowed to use this Website.</span></span></span></p>


<h2><span style="font-size:22.5pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">Intellectual Property Rights</span></span></span></h2>


<p><span style="font-size:12pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">Other than the content you own, under these Terms, Goodfellow Digital company Limited and/or its licensors own all the intellectual property rights and materials contained in this Website.</span></span></span></p>


<p><span style="font-size:12pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">You are granted limited license only for purposes of viewing the material contained on this Website.</span></span></span></p>


<h2><span style="font-size:22.5pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">Restrictions</span></span></span></h2>


<p><span style="font-size:12pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">You are specifically restricted from all of the following:</span></span></span></p>


<ul>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">publishing any Website material in any other media;</span></span></span></li>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">selling, sublicensing and/or otherwise commercializing any Website material;</span></span></span></li>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">publicly performing and/or showing any Website material;</span></span></span></li>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">using this Website in any way that is or may be damaging to this Website;</span></span></span></li>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">using this Website in any way that impacts user access to this Website;</span></span></span></li>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity;</span></span></span></li>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website;</span></span></span></li>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">using this Website to engage in any advertising or marketing.</span></span></span></li>
</ul>


<p><span style="font-size:12pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">Certain areas of this Website are restricted from being access by you and Goodfellow Digital company Limited may further restrict access by you to any areas of this Website, at any time, in absolute discretion. Any user ID and password you may have for this Website are confidential and you must maintain confidentiality as well.</span></span></span></p>


<h2><span style="font-size:22.5pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">Your Content</span></span></span></h2>


<p><span style="font-size:12pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">In these Website Standard Terms and Conditions, &quot;Your Content&quot; shall mean any audio, video text, images or other material you choose to display on this Website. By displaying Your Content, you grant Goodfellow Digital company Limited a non-exclusive, worldwide irrevocable, sub licensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.</span></span></span></p>


<p><span style="font-size:12pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">Your Content must be your own and must not be invading any third-party&#39;s rights. Goodfellow Digital company Limited reserves the right to remove any of Your Content from this Website at any time without notice.</span></span></span></p>


<h2><span style="font-size:22.5pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">No warranties</span></span></span></h2>


<p><span style="font-size:12pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">This Website is provided &quot;as is,&quot; with all faults, and Goodfellow Digital company Limited express no representations or warranties, of any kind related to this Website or the materials contained on this Website. Also, nothing contained on this Website shall be interpreted as advising you.</span></span></span></p>


<h2><span style="font-size:22.5pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">Limitation of liability</span></span></span></h2>


<p><span style="font-size:12pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">In no event shall Goodfellow Digital company Limited, nor any of its officers, directors and employees, shall be held liable for anything arising out of or in any way connected with your use of this Website whether such liability is under contract.&nbsp; Goodfellow Digital company Limited, including its officers, directors and employees shall not be held liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Website.</span></span></span></p>


<h2><span style="font-size:22.5pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">Indemnification</span></span></span></h2>


<p><span style="font-size:12pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">You hereby indemnify to the fullest extent Goodfellow Digital company Limited from and against any and/or all liabilities, costs, demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these Terms.</span></span></span></p>


<h2><span style="font-size:22.5pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">Severability</span></span></span></h2>


<p><span style="font-size:12pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.</span></span></span></p>


<h2><span style="font-size:22.5pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">Variation of Terms</span></span></span></h2>


<p><span style="font-size:12pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">Goodfellow Digital company Limited is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review these Terms on a regular basis.</span></span></span></p>


<h2><span style="font-size:22.5pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">Assignment</span></span></span></h2>


<p><span style="font-size:12pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">The Goodfellow Digital company Limited is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.</span></span></span></p>


<h2><span style="font-size:22.5pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">Entire Agreement</span></span></span></h2>


<p><span style="font-size:12pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">These Terms constitute the entire agreement between Goodfellow Digital company Limited and you in relation to your use of this Website, and supersede all prior agreements and understandings.</span></span></span></p>


<h2><span style="font-size:22.5pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">Governing Law &amp; Jurisdiction</span></span></span></h2>


<p><span style="font-size:12pt"><span style="font-family:Arial,sans-serif"><span style="color:#333333">These Terms will be governed by and interpreted in accordance with the laws of the State of zm, and you submit to the non-exclusive jurisdiction of the state and federal courts located in zm for the resolution of any disputes.</span></span></span></p>


<h1>&nbsp;</h1>

  `;

  return (
    <div>
      <div className="w-full h-[300px] bg-gradient-to-r from-green-50 to-green-100 lg:px-20 xl:px-24 3xl:px-44 pt-8">
        <Navbar />
        <div className="mt-20 text-center font-semibold text-green-900 text-3xl leading-loose">
          Terms & Conditions
        </div>
        <div className="text-center font-bold text-sm">
          Updated at 15 February 2024
        </div>
      </div>
      <div className="bg-gradient-to-r from-green-50 to-green-100 p-10 lg:p-20 xl:p-24 3xl:p-44">
        <div className="rounded-lg bg-gray-50 p-10 lg:p-20 xl:p-24 3xl:p-44">
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
    </div>
  );
};

export default TermsAndConsitionsPage;
