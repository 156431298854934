import React, { useState, useRef, useEffect } from "react";
import { useOnHoverOutsideDownloadButton } from "../hooks/useOnHoverOutsideDownloadButton";
import DownloadRecordsMenu from "../components/DownloadRecordsMenu";
import MTNLogo from "../assets/mtn_logo.png";
import AirtelLogo from "../assets/airtel_logo.png";
import ZamtelLogo from "../assets/zamtel_logo.png";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import DeleteModal from "./DeleteModal";
import RefundModal from "./RefundModal";

import axios from "axios";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";

const SuccessfulPayments = () => {
  const dropdownDownloadMenuRef = useRef(null); // Create a reference for dropdown container
  const [isDownloadMenuDropDownOpen, setDownloadMenuDropDownOpen] =
    useState(false);

  const componentPDF = useRef();

  // State variables
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [idToRefund, setIdToRefund] = useState(null);
  const [referenceId, setReferenceId] = useState(null);
  const [refundAmount, setRefundAmount] = useState(null);
  const [refundCustomer, setRefundCustomer] = useState(null);
  const [refundPaymentMethod, setRefundPaymentMethod] = useState(null);
  const { user } = useSelector((state) => state.auth);

  // Fetch data from server
  useEffect(() => {
    fetchData();
  }, [page, pageSize, search, user]);

  const fetchData = async () => {
    setLoading(true);
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    const requestBody = {
      nob: user.business_id,
      page: page,
      pageSize: pageSize,
      search: search,
    };

    axios
      .post("api/v1/successful/payments/", requestBody, config)
      .then((res) => {
        setData(res.data.data);
        console.log(res.data.data);
        setTotalPages(res.data.last_page);
        setLoading(false);
      })
      .catch((err) => {
        // Handle error
      });
  };

  // Handle page change
  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleDeleteModalClose = () => {
    setShowDeleteModal(false);
    setShowRefundModal(false);
    fetchData();
  };

  const handleRefundModalClose = () => {
    setShowDeleteModal(false);
    setShowRefundModal(false);
    fetchData();
  };

  // Handle page size change
  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
    setPage(1);
  };

  // Handle search change
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  // Handle delete
  const handleDelete = async (row) => {
    setIdToDelete(row.id);
    setReferenceId(row.payment_reference_number);
    setShowDeleteModal(true);
  };

  // Handle delete
  const handleRefund = async (row) => {
    setIdToRefund(row.id);
    setShowRefundModal(true);
    setRefundAmount(row.received_amount);
    setRefundCustomer(row.phone_number);
    setRefundPaymentMethod(row.payment_channel);
  };

  // Define columns for the table
  const columns = [
    {
      Header: (
        <input
          type="checkbox"
          className="rounded border border-gray-400 active:bg-green-800 hover:cursor-pointer checked:bg-green-800"
          checked={selectedRecords.length === data.length}
          onChange={(e) => {
            if (e.target.checked) {
              const ids = data.map((row) => row.id);
              setSelectedRecords(ids);
            } else {
              setSelectedRecords([]);
            }
          }}
        />
      ),
      id: "checkbox",
      Cell: ({ row }) => (
        <div className="flex flex-col items-center justify-center">
          <input
            type="checkbox"
            className="rounded bodrer border-gray-400 active:bg-green-800 hover:cursor-pointer checked:bg-green-800"
            checked={selectedRecords.includes(row.id)}
            onChange={() => {
              if (selectedRecords.includes(row.id)) {
                setSelectedRecords(
                  selectedRecords.filter((id) => id !== row.id)
                );
              } else {
                setSelectedRecords([...selectedRecords, row.id]);
              }
            }}
          />
        </div>
      ),
      width: 40,
    },

    {
      Header: <div className="text-sm font-bold text-green-800">Ref No.</div>,
      accessor: "payment_reference_number",
      className:
        "text-xs text-start text-green-800 text-opacity-80 font-italic",
      minWidth: 60,
    },
    {
      Header: <div className="text-sm font-bold text-green-800">Customer</div>,
      accessor: "phone_number",
      minWidth: 60,
      className: "text-sm text-start text-green-800 text-opacity-80",
    },
    {
      Header: (
        <div className="text-sm font-bold text-green-800">Txn Number</div>
      ),
      accessor: "txn_number",
      className: "text-sm text-end text-green-800 text-opacity-80",
    },
    {
      Header: (
        <div className="text-sm font-bold text-green-800">Payment Channel</div>
      ),
      accessor: "payment_channel",
      minWidth: 60,
      className: "text-sm text-end text-green-800 text-opacity-80",
      Cell: ({ value }) => {
        if (value === "Airtel Money") {
          return (
            <div className="flex flex-col items-center justify">
              <img src={AirtelLogo} alt="airtel money" className="h-5" />
            </div>
          );
        } else if (value === "Zamtel Money") {
          return (
            <div className="flex flex-col items-center justify">
              <img src={ZamtelLogo} alt="airtel money" className="h-5" />
            </div>
          );
        } else if (value === "MTN Money") {
          return (
            <div className="flex flex-col items-center justify">
              <img src={MTNLogo} alt="airtel money" className="h-5" />
            </div>
          );
        } else {
          return null;
        }
      },
    },
    {
      Header: (
        <div className="text-sm font-bold text-green-800 text-end">
          Txn Status
        </div>
      ),
      accessor: "status",
      className: "text-sm text-end text-green-800 text-opacity-80",
      Cell: ({ value }) => {
        if (value === "0" || value === 0) {
          return (
            <button className="text-red-600 bg-red-800 bg-opacity-20 px-4 py-0.5 rounded-full">
              failed
            </button>
          );
        } else if (value === "1" || value === 1) {
          return (
            <button className="text-blue-600 bg-blue-800 bg-opacity-20 px-4 py-0.5 rounded-full">
              pending
            </button>
          );
        } else if (value === "2" || value === 2) {
          return (
            <button className="text-green-600 bg-green-800 bg-opacity-20 px-4 py-0.5 rounded-full">
              success
            </button>
          );
        }
      },
      minWidth: 50,
    },
    {
      Header: (
        <div className="text-sm font-bold text-green-800 text-end">
          Amount (ZMW)
        </div>
      ),
      accessor: "received_amount",
      className: "text-sm text-end text-green-800 text-opacity-80 font-bold",
      Cell: ({ value }) => {
        const str = value;
        const num = parseFloat(str);
        const formattedNum = num.toFixed(2);
        return <div>{formattedNum}</div>;
      },
    },
    {
      Header: <div className="text-sm font-bold text-green-800">Date/Time</div>,
      accessor: "updated_at",
      className: "text-xs text-end text-green-800 text-opacity-80 font-light",
      Cell: ({ value }) => {
        const dateObj = new Date(value);
        const formattedDateString = dateObj.toLocaleString().replace("T", " ");
        return <div>{formattedDateString}</div>;
      },
    },
    {
      Header: <div className="text-sm font-bold text-white">Customer ID</div>,
      accessor: "customer_id",
      className: "text-xs text-end text-white font-light",
      minWidth: 5,
    },
    {
      Header: <div className="text-sm font-bold text-green-800">Controls</div>,
      accessor: "id",
      Cell: ({ value, row }) => {
        return (
          <div className="flex flex-row items-center justify-end relative">
            <button id="more" className="text-green-800">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                />
              </svg>
            </button>

            <div
              id="additional"
              className="absolute flex flex-row items-center space-x-1 rounded shadow-lg bg-white text-green-800 pl-3"
            >
              <div className="flex flex-row items-center space-x-2">
                {row.status === "2" ||
                  (row.status === 2 && (
                    <div
                      onClick={() => handleRefund(row)}
                      className="hover:cursor-pointer hover:text-blue-500"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
                        />
                      </svg>
                    </div>
                  ))}
                <Link
                  to={`/customer/${row.customer_id}`}
                  className="hover:cursor-pointer hover:text-green-500"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                </Link>
                <div
                  onClick={() => handleDelete(row)}
                  className="hover:cursor-pointer hover:text-red-500"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>
                </div>
              </div>
              <div className="text-green-800 text-opacity-40">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                  />
                </svg>
              </div>
            </div>
          </div>
        );
      },
      minWidth: 50,
    },
  ];

  // Function to close dropdown
  const closeDownloadMenu = () => {
    setDownloadMenuDropDownOpen(false);
  };

  useOnHoverOutsideDownloadButton(dropdownDownloadMenuRef, closeDownloadMenu); // Call the hook

  return (
    <div className="w-full h-full">
      {showDeleteModal && (
        <DeleteModal
          id={idToDelete}
          reference_number={referenceId}
          onClose={handleDeleteModalClose}
        />
      )}
      {showRefundModal && (
        <RefundModal
          id={idToRefund}
          refundAmount={refundAmount}
          refundCustomer={refundCustomer}
          refundPaymentMethod={refundPaymentMethod}
          onClose={handleRefundModalClose}
        />
      )}
      <ToastContainer />
      <div className="flex flex-row items-end justify-between mt-4">
        <div>
          <div class="pt-2 relative mx-auto text-gray-600 w-[300px]">
            <input
              class="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-full text-sm focus:outline-none active:outline-green-800 focus:border-green-800 w-full"
              type="search"
              name="search"
              placeholder="search payment..."
              value={search}
              onChange={handleSearchChange}
            />
            <button
              type="submit"
              class="absolute right-0 top-3 mr-2 text-green-800"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="w-8 h-8"
              >
                <path d="M8.25 10.875a2.625 2.625 0 115.25 0 2.625 2.625 0 01-5.25 0z" />
                <path
                  fill-rule="evenodd"
                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.125 4.5a4.125 4.125 0 102.338 7.524l2.007 2.006a.75.75 0 101.06-1.06l-2.006-2.007a4.125 4.125 0 00-3.399-6.463z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="flex flex-row items-center space-x-2">
          <div ref={dropdownDownloadMenuRef} className="relative">
            <div
              onMouseOver={() => setDownloadMenuDropDownOpen(true)}
              className="bg-green-800 px-4 py-1 text-green-200 flex flex-row items-center space-x-1 rounded shadow"
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 9.75v6.75m0 0l-3-3m3 3l3-3m-8.25 6a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                  />
                </svg>
              </span>
              <span className="text-sm">Download</span>
            </div>
            {isDownloadMenuDropDownOpen && (
              <DownloadRecordsMenu handleExcel={data} fetchDataPDF={data} />
            )}
          </div>
        </div>
      </div>

      <div ref={componentPDF} style={{ width: "100%" }}>
        <div className="h-full border-t-2 border-green-800 mt-2 bg-white overflow-y-scroll">
          {/* table starts here */}

          <div>
            {/* Payments table */}
            <ReactTable
              data={data}
              columns={columns}
              loading={loading}
              showPageSizeOptions={true}
              pageSizeOptions={[10, 20, 50, 100]}
              defaultPageSize={pageSize}
              pageSize={pageSize}
              page={page - 1}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              manual
              pages={totalPages}
              className="-striped -highlight"
              id="payments-table"
            />
          </div>

          {/* table ends here */}
        </div>
      </div>
    </div>
  );
};

export default SuccessfulPayments;
