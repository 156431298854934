import {createStore,applyMiddleware} from 'redux';
import logger from 'redux-logger';
import {persistStore} from 'redux-persist';
import thunk from 'redux-thunk';

import rootReducer from '../reducers/rootReducer';

const middleware = [thunk];

middleware.push(logger);


export const store = createStore(
    rootReducer,
    applyMiddleware(...middleware)
     );

export const persister = persistStore(store);