import React from 'react'
import { ToastContainer } from 'react-toastify'
import DashboardNav from '../components/DashboardNav'
import SideBar from '../components/SideBar'

const InvoicesPage = () => {
  return (
    <>
      <div className='flex flex-row items-start min-h-screen'>
      <div className='min-h-screen w-1/6'>
      <ToastContainer />
      <SideBar/>
      </div>
      <div className='min-h-screen w-5/6 bg-green-800 bg-opacity-10'>
        <DashboardNav/>
        <div className='w-full h-full overflow-y-auto p-8'>
        <div>Invoices Page</div>
        </div>
      </div> 
      </div>
    </>
  )
}

export default InvoicesPage