import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import axios from "axios";

const RefundPolicy = () => {
  const [updatedDate, setUpdatedDate] = useState("");
  const [updatedContent, setUpdatedContent] = useState("");

  useEffect(() => {
    fetchContent();
  });

  const fetchContent = (async) => {
    const config = {
      header: {
        Accept: "application/json",
      },
    };

    try {
      const response = axios.get("/api/v2/privacy-policy", config);
    } catch (error) {
      console.error(error);
    }
  };

  const content = ``;

  return (
    <div>
      <div className="w-full h-[300px] bg-gradient-to-r from-green-50 to-green-100 lg:px-20 xl:px-24 3xl:px-44 pt-8">
        <Navbar />
        <div className="mt-20 text-center font-semibold text-green-900 text-3xl leading-loose">
          Refunds Policy
        </div>
        <div className="text-center font-bold text-sm">
          Updated at 15 February 2024
        </div>
      </div>
      <div className="bg-gradient-to-r from-green-50 to-green-100 p-10 lg:p-20 xl:p-24 3xl:p-44">
        <div className="rounded-lg bg-gray-50 p-10 lg:p-20 xl:p-24 3xl:p-44">
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
    </div>
  );
};

export default RefundPolicy;
