import {Redirect, Route} from 'react-router-dom';
import {useSelector} from 'react-redux';

const PrivateRoute = ({component: Component, ...rest}) => {
    const {user} = useSelector(state => state.auth);
    return (
        <Route
            {...rest}
            render = {
                (props) => 
                    // sessionStorage.getItem("client-token") ? (<Component {...props}/>) : (<Redirect to="/home"/>)
                    Object.keys(user).length > 0 ? (<Component {...props}/>) : (<Redirect to="/"/>)
                
            }
        />
    );
}

export default PrivateRoute;