import React, { useEffect, useState } from "react";
import axios from "axios";
import SuccessIcon from "../assets/success_icon.png";
import { useParams, useHistory } from "react-router-dom";
import QRCode from "qrcode.react";
import LoaderComponent from "../components/LoaderComponent";

const ReceiptPage = () => {
  const history = useHistory();
  const { shortUrl } = useParams();
  const [isLoadingReceipt, setIsLoadingReceipt] = useState(false);
  const [noReceipt, setNoReceipt] = useState(false);
  const [business_logo, setBusiness_logo] = useState("");
  const [business_name, setBusiness_name] = useState("");
  const [business_phone_number, setBusiness_phone_number] = useState("");
  const [transaction_date, setTransaction_date] = useState("");
  const [lastThreeDigits, setLastThreeDigits] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [payment_reference_number, setPayment_reference_number] = useState("");
  const [qrcode_value, setQrcode_value] = useState("http://geepay.co.zm");
  const [is_refunded, setIs_refunded] = useState("");

  useEffect(() => {
    getPaymentDetails();
  }, [shortUrl]);

  const getPaymentDetails = () => {
    setIsLoadingReceipt(true);
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("api/v2/receipt", { shortUrl }, config)
      .then((res) => {
        setIsLoadingReceipt(false);
        setBusiness_logo(res.data.business_logo);
        setBusiness_name(res.data.business_name);
        setBusiness_phone_number(res.data.business_phone_number);
        setTransaction_date(res.data.updated_at);
        setLastThreeDigits(res.data.customer_phone_number);
        setDescription(res.data.description);
        setAmount(res.data.amount);
        setPaymentMethod(res.data.payment_channel);
        setCustomerName(res.data.customer_name);
        setPayment_reference_number(res.data.payment_reference_number);
        setQrcode_value(res.data.short_url);
        setIs_refunded(res.data.is_refunded);
      })
      .catch((err) => {
        setIsLoadingReceipt(false);
        setNoReceipt(true);
        console.log(err);
      });
  };

  return (
    <>
      {isLoadingReceipt ? (
        <LoaderComponent />
      ) : noReceipt ? (
        <div className="bg-green-800 bg-opacity-40 mx-auto flex flex-col items-center justify-center w-full h-screen p-6">
          <div className="bg-gray-50 rounded shadow p-8 w-full md:max-w-md flex flex-col items-center mx-auto">
            <div className="p-1 rounded-full bg-red-400 bg-opacity-20 flex flex-col items-center justify-center text-red-800 h-16 w-16">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="34"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                <line x1="12" y1="9" x2="12" y2="13"></line>
                <line x1="12" y1="17" x2="12.01" y2="17"></line>
              </svg>
            </div>
            <div className="text-center text-md text-red-800 mt-8">
              Oops!! No receipt found...
            </div>
            <div className="mt-8">
              <div className="flex flex-row items-center justify-center mt-5">
                <div className="text-xs text-center text-green-800">
                  THANK YOU
                </div>
              </div>
              <div className="flex flex-row items-center justify-center space-x-1 mt-2">
                <div className="text-yellow-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <rect
                      x="3"
                      y="11"
                      width="18"
                      height="11"
                      rx="2"
                      ry="2"
                    ></rect>
                    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                  </svg>
                </div>
                <div className="text-yellow-500 text-sm">Secured by GeePay</div>
              </div>
              <div className="flex flex-row items-center justify-center mt-1">
                <a
                  href="https://www.geepay.co.zm"
                  className="text-xs text-center text-green-800"
                >
                  www.geepay.co.zm
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-green-800 bg-opacity-40 mx-auto flex flex-col items-center justify-center w-full h-screen p-6">
          <div className="bg-gray-50 rounded shadow p-8 w-full md:max-w-md flex flex-col items-center mx-auto">
            <div className="p-1 rounded-full bg-green-800 bg-opacity-20 flex flex-col items-center justify-center text-green-800 h-16 w-16">
              <img src={SuccessIcon} className="h-10 w-10" alt="success" />
            </div>

            <div className="w-full mt-4">
              <div className="flex flex-col items-center justify-center mb-5">
                <div className="text-md text-green-800 leading-loose font-bold mb-2">
                  RECEIPT
                </div>
                {is_refunded === 1 && (
                  <div className="text-sm text-blue-800 leading-loose font-bold mb-3">
                    (Refunded)
                  </div>
                )}
                <div className="mb-2">
                  <img
                    src={business_logo}
                    className="h-10"
                    alt="geepay zambia"
                  />
                </div>
                <div className="text-sm text-green-800 font-bold">
                  {business_name}
                </div>
                <div className="text-xs text-green-800 font-bold">
                  Cell: {business_phone_number}
                </div>
              </div>

              <div className="mb-6">
                <div className="text-xs text-green-800">
                  Date: {transaction_date}
                </div>
                {customerName && (
                  <div className="text-xs text-green-800">
                    To : {customerName}
                  </div>
                )}
                {lastThreeDigits && (
                  <div className="text-xs text-green-800 inline-flex">
                    Paid via : {paymentMethod} ending with ***
                    {lastThreeDigits}
                  </div>
                )}
                <div className="text-xs text-green-800">
                  Ref No: {payment_reference_number}
                </div>
              </div>
              <div>
                <div className="flex flex-row items-center justify-between border-b-4 border-green-800 mb-4">
                  <div className="text-sm text-green-800 font-bold">
                    DESCRIPTION
                  </div>
                  <div className="text-sm text-green-800 font-bold">TOTAL</div>
                </div>
                <div className="w-full grid grid-cols-1 md:grid-cols-3">
                  <div className="col-span-2">
                    <p className="break-all text-xs text-green-800">
                      {description}
                    </p>
                  </div>
                  <div className="text-sm text-green-800 font-light col-span-1 text-end">
                    {amount.toLocaleString("en-US", {
                      style: "currency",
                      currency: "ZMW",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between border-t-4 border-green-800 border-dotted mt-10">
                  <div className="text-sm text-green-800 font-bold">
                    Grand Total
                  </div>
                  <div className="text-sm text-green-800 font-bold">
                    {amount.toLocaleString("en-US", {
                      style: "currency",
                      currency: "ZMW",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                </div>
              </div>

              <div className="flex flex-row items-center justify-center mt-5">
                <div className="text-xs text-center text-green-800">
                  THANK YOU
                </div>
              </div>
              <div className="flex flex-row items-center justify-center space-x-1 mt-2">
                <div className="text-yellow-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <rect
                      x="3"
                      y="11"
                      width="18"
                      height="11"
                      rx="2"
                      ry="2"
                    ></rect>
                    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                  </svg>
                </div>
                <div className="text-yellow-500 text-sm">Secured by GeePay</div>
              </div>
              <div className="flex flex-row items-center justify-center mt-1">
                <a
                  href="https://www.geepay.co.zm"
                  className="text-xs text-center text-green-800"
                >
                  www.geepay.co.zm
                </a>
              </div>
              <div className="flex flex-col items-center justify-center h-20 mt-3">
                <QRCode size={60} value={qrcode_value} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReceiptPage;
