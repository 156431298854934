import { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";

const RecentPayments = () => {
  const { user } = useSelector((state) => state.auth);
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
    };

    axios.get("api/v1/business/recent/payments", config).then((response) => {
      setPayments(response.data);
    });
  }, [user, user.business_id]);

  return (
    <div>
      <div className="pt-6 bg-white shadow rounded">
        <div className="px-6 border-b">
          <div className="flex flex-wrap items-center mb-6">
            <h3 className="text-md font-bold">Recent Payments</h3>
            <Link
              to="/payments"
              className="ml-auto flex items-center py-2 px-3 text-xs text-white bg-green-800 hover:bg-green-600 rounded"
            >
              <span className="mr-1"></span>
              <span>View More</span>
            </Link>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            <thead>
              <tr className="text-xs text-gray-500 text-left">
                <th className="flex items-center pl-6 py-4 font-medium">
                  <input className="mr-3 rounded" type="checkbox" name id />
                  <a className="flex items-center" href="#">
                    <span>Reference ID</span>
                  </a>
                </th>
                <th className="py-4 font-medium">
                  <a className="flex items-center" href="#">
                    <span>Date</span>
                  </a>
                </th>
                <th className="py-4 font-medium">
                  <a className="flex items-center" href="#">
                    <span>Description</span>
                  </a>
                </th>

                <th className="py-4 font-medium">
                  <a className="flex items-center" href="#">
                    <span>Amount (ZMW)</span>
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              {payments.map((payment) => (
                <tr className="text-xs" key={payment.id}>
                  <td className="flex items-center py-5 px-6 font-medium">
                    <input className="mr-3 rounded" type="checkbox" name id />
                    <p>{payment.payment_reference_number}</p>
                  </td>
                  <td className="font-medium">{payment.updated_at}</td>
                  <td className="font-medium">{payment.description}</td>

                  <td className="text-center">
                    {payment.received_amount.toLocaleString("en-US", {
                      style: "currency",
                      currency: "ZMW",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RecentPayments;
