import React from "react";
import Fade from "react-reveal/Fade";
import MastercardLogo from "../assets/mastercard.png";
import MTNLogo from "../assets/mtn_logo.png";
import AirtelLogo from "../assets/airtel_logo.png";
import ZamtelLogo from "../assets/zamtel_logo.png";
import VisaLogo from "../assets/visa.png";

const OurPartners = () => {
  return (
    <div className="py-20">
      <div className="text-green-800 font-bold text-4xl text-center mb-10">
        Our Partners
      </div>
      <div className="flex items-center justify-center">
        <div className="px-10 xl:px-44 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 items-center justify-center">
          <Fade left>
            <div className="h-20">
              <img src={AirtelLogo} className="h-20" />
            </div>
          </Fade>

          <Fade right>
            <div className="h-20">
              <img src={ZamtelLogo} className="h-20" />
            </div>
          </Fade>

          <Fade left>
            <div className="h-20">
              <img src={MTNLogo} className="h-20" />
            </div>
          </Fade>

          {/* <Fade right>
            <div className="h-20">
              <img src={VisaLogo} className="h-20" />
            </div>
          </Fade>
          <Fade right>
            <div className="h-20">
              <img src={MastercardLogo} className="h-20" />
            </div>
          </Fade> */}
        </div>
      </div>
    </div>
  );
};

export default OurPartners;
