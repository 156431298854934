import React from "react";
import Fade from "react-reveal/Fade";
import Code from "../assets/code.png";
import CodeFile from "../assets/code_file.png";
import { Link } from "react-router-dom";

const DeveloperMenu = () => {
  return (
    <>
      <Fade top>
        <div className="w-[280px] py-6 rounded-md shadow-md bg-green-50 z-50 absolute top-[30px] -left-8">
          <Link
            to="/api-documentation"
            className="flex flex-row items-center space-x-3 w-full mb-6 hover:bg-green-100 px-6 py-3 hover:cursor-pointer"
          >
            <div className="w-10 h-10 rounded-md bg-green-900 flex items-center justify-center">
              <img src={Code} alt="gpay" className="h-5" />
            </div>
            <div>
              <div className="text-sm font-medium text-green-800 text-start">
                Documentation
              </div>
              <div className="text-xs font-light text-green-800 text-start">
                Learn the power of our APIs
              </div>
            </div>
          </Link>
        </div>
      </Fade>
    </>
  );
};

export default DeveloperMenu;
