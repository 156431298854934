import React from "react";
import ReactApexChart from "react-apexcharts";

const DashboardGraph = () => {
  const series = [
    {
      name: "Payments",
      type: "column",
      data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30, 44],
    },
    {
      name: "Refunds",
      type: "area",
      data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43, 44],
    },
    {
      name: "Transfers",
      type: "line",
      data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39, 44],
    },
    {
        name: "Disputes",
        type: "bar",
        data: [10, 15, 26, 20, 35, 25, 44, 22, 39, 86, 19, 44],
      },
  ];

  const options = {
    chart: {
      height: 310,
      type: "line",
      stacked: false,
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
            enabled: true,
            delay: 150
        },
        dynamicAnimation: {
            enabled: true,
            speed: 350
        }
    }
    },
    
    
    theme: {
        mode: 'light', 
        palette: 'palette5', 
        monochrome: {
            enabled: false,
            color: '#255aee',
            shadeTo: 'light',
            shadeIntensity: 0.65
        },
    },
    stroke: {
      width: [0, 2, 5],
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },

    fill: {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: false,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    markers: {
      size: 0,
    },
    xaxis: {
      type: "categories",
    },
    yaxis: {
      title: {
        text: "Points",
      },
      min: 0,
    }
    
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={310}
      />
    </div>
  );
};

export default DashboardGraph;
