import React, { useState } from 'react';
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function DeleteModal(props) {
	const history = useHistory();
	const [id, setId] = useState(props.id);
	const [deleteModalLoading, setDeleteModalLoading] = useState(false);
	let [color] = useState("#ffffff");
	const override = css`
    display: block;
    margin: 0 auto;
    border-color: green;
  `;



	const handleDeleteSelectedRecord = async () => {
		setDeleteModalLoading(true);
		
		const config = {
			header: {
				"Accept": "application/json"
			}
		}
		axios.post('api/v1/delete/payment', { id }, config)
			.then(res => {
				if(res.status === 200){
					history.push("/payments");
					props.onClose();
          			toast.success("Payment has been deleted successfully");
				}
				setDeleteModalLoading(false);
				
			})
			.catch(err => {
				setDeleteModalLoading(false);
				props.onClose();
				history.push("/payments");
				toast.error("Oops, something went wrong. Try again later");
			});
	}


  return (
    <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster bg-gray-900 bg-opacity-40">
		<div
			className="border border-green-800 shadow-lg modal-container bg-white w-11/12 md:max-w-md mx-auto rounded-lg z-50 overflow-y-auto">
			{ deleteModalLoading ? <div>
				<div className="m-auto py-20">
                    <div className="flex flex-col items-center justify-center text-red-700">
                      Deleting...
                    </div>
                    <ClipLoader
                      color={color}
                      loading="true"
                      css={override}
                      size={50}
                    />
                  </div>
			</div>:<div className="modal-content py-4 text-left px-6">
		
				<div className="flex justify-between items-center pb-3">
					<p className="text-md font-bold text-red-800">Delete Payment Record</p>
					<div onClick={props.onClose} className="modal-close cursor-pointer z-50">
						<svg className="fill-current text-red-800" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
							viewBox="0 0 18 18">
							<path
								d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
							</path>
						</svg>
					</div>
				</div>
	
				<div className="my-5">
					<p className='text-sm text-green-800'>Are you sure you want to delete payment {props.reference_number}?</p>
				</div>

				<div className="flex flex-row justify-end space-x-2 pt-2">
					<button onClick={props.onClose}
						className="focus:outline-none modal-close px-4 bg-gray-400 py-1 rounded-lg text-green-800 hover:bg-gray-300">Cancel</button>
					<button type='submit' onClick={handleDeleteSelectedRecord}
						className="focus:outline-none px-4 bg-red-800 py-1 rounded-lg text-white hover:bg-red-600">Confirm</button>
				</div>
			</div>}
		</div>
	</div>
  );
}

export default DeleteModal;
