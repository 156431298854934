import React, { useState } from "react";
import Navbar from "../components/Navbar";
import { CopyToClipboard } from "react-copy-to-clipboard";
import JSONPretty from "react-json-view";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/prism";

const ApiDocumentationPage = () => {
  // Step 3: Implement the tab functionality within the component
  const [activeTab, setActiveTab] = useState(1);
  const [activeMenu, setActiveMenu] = useState(1);
  const [copied, setCopied] = useState(false);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const handleMenuClick = (menuIndex) => {
    setActiveMenu(menuIndex);
  };

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  const renderJSON = (json) => {
    if (!json) return null;

    return <pre>{JSON.stringify(json, null, 2)}</pre>;
  };

  const credentialsData = {
    secret_id:
      "7XsAmDLXJLHR2dWTh6vdxmX8yYBK3Qmgd3fQZLDFL4LEkdps44bYdQ5Wm5BKQb69",
    access_token:
      "afDO3e1uzqulG694RVawuo8UwbmiyCkS30UR60HGvufU8eCblqEe9oVhgFaQMZ1A",
  };

  const curlBodyCode = `
    curl -X POST \\
      https://control.geepay.co.zm/api/v2/pay \\
      -H 'Content-Type: application/json' \\
      -d '{
        "secret_id":"xxxxxxx-xxxxxxx-xxxx-xxxx-xxxxxxxxxx",
        "access_token":"xxxxxxx-xxxxxxx-xxxx-xxxx-xxxxxxxxxx",
        "txn_number":"abc12398e7b1",
        "amount":"1.99",
        "payment_method":"mm",
        "phone_number":"260779205949",
        "description":"Today Testing"
      }'
  `;

  const jsBodyCode = `
    fetch('https://control.geepay.co.zm/api/v2/pay', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        secret_id: 'xxxxxxx-xxxxxxx-xxxx-xxxx-xxxxxxxxxx',
        access_token: 'xxxxxxx-xxxxxxx-xxxx-xxxx-xxxxxxxxxx',
        txn_number: 'abc12398e7b1',
        amount: '1.99',
        payment_method: 'mm',
        phone_number: '260779205949',
        description: 'Today Testing'
      })
    })
    .then(response => response.json())
    .then(data => console.log(data))
    .catch(error => console.error('Error:', error));
  `;

  const pyBodyCode = `
    import requests

    url = 'https://control.geepay.co.zm/api/v2/pay'
    payload = {
        "secret_id": "xxxxxxx-xxxxxxx-xxxx-xxxx-xxxxxxxxxx",
        "access_token": "xxxxxxx-xxxxxxx-xxxx-xxxx-xxxxxxxxxx",
        "txn_number": "abc12398e7b1",
        "amount": "1.99",
        "payment_method": "mm",
        "phone_number": "260779205949",
        "description": "Today Testing"
    }
    headers = {
        'Content-Type': 'application/json'
    }
    response = requests.post(url, json=payload, headers=headers)
    print(response.json())
  `;

  const phpBodyCode = `
    <?php

    $data = array(
        "secret_id" => "xxxxxxx-xxxxxxx-xxxx-xxxx-xxxxxxxxxx",
        "access_token" => "xxxxxxx-xxxxxxx-xxxx-xxxx-xxxxxxxxxx",
        "txn_number" => "abc12398e7b1",
        "amount" => "1.99",
        "payment_method" => "mm",
        "phone_number" => "260779205949",
        "description" => "Today Testing"
    );

    $payload = json_encode($data);

    $ch = curl_init('https://control.geepay.co.zm/api/v2/pay');
    curl_setopt($ch, CURLOPT_CUSTOMREQUEST, "POST");
    curl_setopt($ch, CURLOPT_POSTFIELDS, $payload);
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
    curl_setopt($ch, CURLOPT_HTTPHEADER, array(
        'Content-Type: application/json',
        'Content-Length: ' . strlen($payload))
    );

    $result = curl_exec($ch);
    curl_close($ch);

    echo $result;
    ?>
  `;

  const curlResponseCode = `
      {
        "success": true,
        "message": "transaction successful",
        "txn_number": "BGYFK3GV6AQ3",
        "ref_number": "Ref4454631709027029",
        "short_url": "https://business.geepay.co.zm/r/hQfLg",
        "date": "2024-02-27T09:44:12.136379Z"
      }
  `;

  return (
    <div>
      <Navbar />
      <div className="w-full min-h-screen bg-gradient-to-r from-green-50 to-green-100">
        <div className="h-[200px] w-full bg-green-950 mb-10 flex flex-col items-center justify-center">
          <div className="text-white text-3xl font-bold">API Documentation</div>
          <div className="text-white text-md font-light">
            {"[ No time to check time, integrate now ]"}
          </div>
        </div>
        <div className="w-full h-[1200px] py-10 flex flex-row items-start p-10 lg:px-20 xl:px-24 3xl:px-44">
          <div className="flex flex-col w-full h-full md:w-2/12">
            <div
              className={`${
                activeMenu === 1
                  ? "bg-green-800 px-6 py-2 rounded-xl text-white hover:cursor-pointer"
                  : "px-6 py-2 bg-green-700 bg-opacity-20 rounded-tl-xl rounded-tr-xl text-green-900 hover:bg-green-700 hover:bg-opacity-20 hover:cursor-pointer"
              }`}
              onClick={() => handleMenuClick(1)}
            >
              Getting Started
            </div>
            <div
              className={`${
                activeMenu === 2
                  ? "bg-green-800 px-6 py-2 rounded-xl text-white hover:cursor-pointer"
                  : "px-6 py-2 bg-green-700 bg-opacity-20 text-green-900 hover:bg-green-700 hover:bg-opacity-20 hover:cursor-pointer"
              }`}
              onClick={() => handleMenuClick(2)}
            >
              Authentication
            </div>
            <div
              className={`${
                activeMenu === 3
                  ? "bg-green-800 px-6 py-2 rounded-xl text-white hover:cursor-pointer"
                  : "px-6 py-2 bg-green-700 bg-opacity-20 text-green-900 hover:bg-green-700 hover:bg-opacity-20 hover:cursor-pointer"
              }`}
              onClick={() => handleMenuClick(3)}
            >
              Process Payments
            </div>
          </div>
          <div className="w-full h-full md:w-10/12 px-20">
            <div className="">
              {activeMenu === 1 && (
                <div>
                  <div className="font-bold text-lg leading-loose mb-10">
                    Getting started
                  </div>
                  <p className="text-sm text-gray-700 mb-10">
                    This guide will get you all set up and ready to use the
                    GeePay API. We'll cover how to get started using one of our
                    API clients and how to make your first API request. We'll
                    also look at where to go next to find all the information
                    you need to take full advantage of our powerful REST API.
                  </p>
                  <div className="rounded-xl border-2 border-green-700 border-opacity-40 bg-green-700 bg-opacity-5 p-8 flex flex-row items-center space-x-2">
                    <div className="text-green-400">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <circle cx="12" cy="12" r="10"></circle>
                        <line x1="12" y1="8" x2="12" y2="12"></line>
                        <line x1="12" y1="16" x2="12.01" y2="16"></line>
                      </svg>
                    </div>
                    <p className="text-xs text-green-700">
                      Before you can make requests to the GeePay API, you will
                      need to grab your API key from your dashboard. You find it
                      under{" "}
                      <a
                        href="https://business.geepay.co.zm"
                        target="_blank"
                        className="text-blue-600"
                      >
                        Settings {">>"} API
                      </a>
                    </p>
                  </div>

                  <div className="text-md font-bold mt-10 mb-8">Base URLs</div>
                  <div className="text-md font-bold mt-10 mb-8">
                    Test Environment
                  </div>
                  <div className="bg-gray-950 rounded-xl py-4 px-6 w-full text-purple-400 text-sm flex flex-row items-center justify-between">
                    https://test.geepay.co.zm
                    <div>
                      <CopyToClipboard
                        text={"https://test.geepay.co.zm"}
                        onCopy={handleCopy}
                      >
                        <button className="relative flex flex-row items-center py-1 px-4 rounded-full bg-gray-500 space-x-1 group hover:cursor-point">
                          <div className="text-gray-400 group-hover:text-white group-hover:cursor-pointer">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                              <rect
                                x="8"
                                y="2"
                                width="8"
                                height="4"
                                rx="1"
                                ry="1"
                              ></rect>
                            </svg>
                          </div>
                          <div className="text-xs text-gray-400 group-hover:text-white group-hover:cursor-pointer">
                            Copy
                          </div>
                          {copied ? (
                            <p className="absolute -top-5 right-4 text-green-500 text-xs">
                              Copied!
                            </p>
                          ) : null}
                        </button>
                      </CopyToClipboard>
                    </div>
                  </div>
                  <div className="text-md font-bold mt-10 mb-8">
                    Production Environment
                  </div>
                  <div className="bg-gray-950 rounded-xl py-4 px-6 w-full text-green-400 text-sm flex flex-row items-center justify-between">
                    https://control.geepay.co.zm
                    <div>
                      <CopyToClipboard
                        text={"https://control.geepay.co.zm"}
                        onCopy={handleCopy}
                      >
                        <button className="relative flex flex-row items-center py-1 px-4 rounded-full bg-gray-500 space-x-1 group hover:cursor-point">
                          <div className="text-gray-400 group-hover:text-white group-hover:cursor-pointer">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                              <rect
                                x="8"
                                y="2"
                                width="8"
                                height="4"
                                rx="1"
                                ry="1"
                              ></rect>
                            </svg>
                          </div>
                          <div className="text-xs text-gray-400 group-hover:text-white group-hover:cursor-pointer">
                            Copy
                          </div>
                          {copied ? (
                            <p className="absolute -top-5 right-4 text-green-500 text-xs">
                              Copied!
                            </p>
                          ) : null}
                        </button>
                      </CopyToClipboard>
                    </div>
                  </div>
                  <div className="text-md font-bold mt-10 mb-8">
                    Choose your client
                  </div>
                  <p className="text-sm text-gray-700 mb-10">
                    Before making your first API request, you need to pick which
                    API client you will use. In addition to good ol' cURL HTTP
                    requests, GeePay offers clients for JavaScript, Python, and
                    PHP. In the following example, you can see how to install
                    each client.
                  </p>
                  <div>
                    <div className="w-full rounded-xl bg-gray-900">
                      <div className="w-full rounded-tl-xl rounded-tr-xl bg-gray-700 flex flex-row border-b-2 border-gray-600">
                        <div
                          className={`${
                            activeTab === 1
                              ? "border-b-2 border-green-400 text-green-400 bg-gray-700 py-2 px-6 rounded-tl-xl hover:cursor-pointer text-sm"
                              : "border-b-2 border-transparent bg-gray-700 py-2 px-6 rounded-tl-xl text-gray-400 hover:text-gray-300 hover:cursor-pointer text-sm"
                          }`}
                          onClick={() => handleTabClick(1)}
                        >
                          cURL
                        </div>
                        <div
                          className={`${
                            activeTab === 2
                              ? "border-b-2 border-green-400 text-green-400 bg-gray-700 py-2 px-6 rounded-tl-xl hover:cursor-pointer text-sm"
                              : "border-b-2 border-transparent bg-gray-700 py-2 px-6 rounded-tl-xl text-gray-400 hover:text-gray-300 hover:cursor-pointer text-sm"
                          }`}
                          onClick={() => handleTabClick(2)}
                        >
                          JavaScript
                        </div>
                        <div
                          className={`${
                            activeTab === 3
                              ? "border-b-2 border-green-400 text-green-400 bg-gray-700 py-2 px-6 rounded-tl-xl hover:cursor-pointer text-sm"
                              : "border-b-2 border-transparent bg-gray-700 py-2 px-6 rounded-tl-xl text-gray-400 hover:text-gray-300 hover:cursor-pointer text-sm"
                          }`}
                          onClick={() => handleTabClick(3)}
                        >
                          Python
                        </div>
                        <div
                          className={`${
                            activeTab === 4
                              ? "border-b-2 border-green-400 text-green-400 bg-gray-700 py-2 px-6 rounded-tl-xl hover:cursor-pointer text-sm"
                              : "border-b-2 border-transparent bg-gray-700 py-2 px-6 rounded-tl-xl text-gray-400 hover:text-gray-300 hover:cursor-pointer text-sm"
                          }`}
                          onClick={() => handleTabClick(4)}
                        >
                          PHP
                        </div>
                      </div>
                      <div className="tab-content">
                        {activeTab === 1 && (
                          <div className="p-6">
                            <div className="flex flex-row items-center justify-between">
                              <div className="text-gray-500 text-sm mb-2">
                                # cURL is most likely already installed on your
                                machine
                              </div>
                              <CopyToClipboard
                                text={"curl --version"}
                                onCopy={handleCopy}
                              >
                                <button className="relative flex flex-row items-center py-1 px-4 rounded-full bg-gray-500 space-x-1 group hover:cursor-point">
                                  <div className="text-gray-400 group-hover:text-white group-hover:cursor-pointer">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    >
                                      <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                                      <rect
                                        x="8"
                                        y="2"
                                        width="8"
                                        height="4"
                                        rx="1"
                                        ry="1"
                                      ></rect>
                                    </svg>
                                  </div>
                                  <div className="text-xs text-gray-400 group-hover:text-white group-hover:cursor-pointer">
                                    Copy
                                  </div>
                                  {copied ? (
                                    <p className="absolute -top-5 right-4 text-green-500 text-xs">
                                      Copied!
                                    </p>
                                  ) : null}
                                </button>
                              </CopyToClipboard>
                            </div>

                            <p className="text-sm text-purple-400">
                              curl{" "}
                              <span className="text-sm text-green-400">
                                --version
                              </span>
                            </p>
                          </div>
                        )}
                        {activeTab === 2 && (
                          <div>
                            <div className="p-6">
                              <div className="flex flex-row items-center justify-between">
                                <div className="text-gray-500 text-sm mb-2">
                                  # Install the GeePay JavaScript SDK
                                </div>
                                <CopyToClipboard
                                  text={
                                    "npm install @goodfellow/geepay-api --save"
                                  }
                                  onCopy={handleCopy}
                                >
                                  <button className="relative flex flex-row items-center py-1 px-4 rounded-full bg-gray-500 space-x-1 group hover:cursor-point">
                                    <div className="text-gray-400 group-hover:text-white group-hover:cursor-pointer">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      >
                                        <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                                        <rect
                                          x="8"
                                          y="2"
                                          width="8"
                                          height="4"
                                          rx="1"
                                          ry="1"
                                        ></rect>
                                      </svg>
                                    </div>
                                    <div className="text-xs text-gray-400 group-hover:text-white group-hover:cursor-pointer">
                                      Copy
                                    </div>
                                    {copied ? (
                                      <p className="absolute -top-5 right-4 text-green-500 text-xs">
                                        Copied!
                                      </p>
                                    ) : null}
                                  </button>
                                </CopyToClipboard>
                              </div>
                              <p className="text-sm text-purple-400">
                                npm{" "}
                                <span className="text-sm text-green-400">
                                  install @goodfellow/geepay-api --save
                                </span>
                              </p>
                            </div>
                          </div>
                        )}
                        {activeTab === 3 && (
                          <div>
                            <div>
                              <div className="p-6">
                                <div className="flex flex-row items-center justify-between">
                                  <div className="text-gray-500 text-sm mb-2">
                                    # Install the GeePay Python SDK
                                  </div>
                                  <CopyToClipboard
                                    text={"pip install geepay_api"}
                                    onCopy={handleCopy}
                                  >
                                    <button className="relative flex flex-row items-center py-1 px-4 rounded-full bg-gray-500 space-x-1 group hover:cursor-point">
                                      <div className="text-gray-400 group-hover:text-white group-hover:cursor-pointer">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        >
                                          <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                                          <rect
                                            x="8"
                                            y="2"
                                            width="8"
                                            height="4"
                                            rx="1"
                                            ry="1"
                                          ></rect>
                                        </svg>
                                      </div>
                                      <div className="text-xs text-gray-400 group-hover:text-white group-hover:cursor-pointer">
                                        Copy
                                      </div>
                                      {copied ? (
                                        <p className="absolute -top-5 right-4 text-green-500 text-xs">
                                          Copied!
                                        </p>
                                      ) : null}
                                    </button>
                                  </CopyToClipboard>
                                </div>
                                <p className="text-sm text-purple-400">
                                  pip{" "}
                                  <span className="text-sm text-green-400">
                                    install geepay_api
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                        {activeTab === 4 && (
                          <div>
                            <div>
                              <div className="p-6">
                                <div className="flex flex-row items-center justify-between">
                                  <div className="text-gray-500 text-sm mb-2">
                                    # Install the GeePay PHP SDK
                                  </div>
                                  <CopyToClipboard
                                    text={"composer require geepay/sdk"}
                                    onCopy={handleCopy}
                                  >
                                    <button className="relative flex flex-row items-center py-1 px-4 rounded-full bg-gray-500 space-x-1 group hover:cursor-point">
                                      <div className="text-gray-400 group-hover:text-white group-hover:cursor-pointer">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        >
                                          <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                                          <rect
                                            x="8"
                                            y="2"
                                            width="8"
                                            height="4"
                                            rx="1"
                                            ry="1"
                                          ></rect>
                                        </svg>
                                      </div>
                                      <div className="text-xs text-gray-400 group-hover:text-white group-hover:cursor-pointer">
                                        Copy
                                      </div>
                                      {copied ? (
                                        <p className="absolute -top-5 right-4 text-green-500 text-xs">
                                          Copied!
                                        </p>
                                      ) : null}
                                    </button>
                                  </CopyToClipboard>
                                </div>
                                <p className="text-sm text-purple-400">
                                  composer{" "}
                                  <span className="text-sm text-green-400">
                                    require geepay/sdk
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/*  <div
                    onClick={() => handleMenuClick(4)}
                    className="mt-8 flex flex-row items-center space-x-1 text-green-500 text-sm hover:text-green-400 hover:cursor-pointer"
                  >
                    <div>Check out our list of first-party SDKs</div>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M5 12h13M12 5l7 7-7 7" />
                      </svg>
                    </div> 
                  </div> */}
                </div>
              )}
              {activeMenu === 2 && (
                <div>
                  <div className="font-bold text-lg leading-loose mb-10">
                    <div className="text-md font-bold">Authorisation</div>
                    <p className="text-sm font-normal text-gray-700 mt-10 mb-10">
                      After picking your preferred client, you are ready to make
                      your first call to the GeePay API.
                    </p>
                    <p className="text-sm font-normal text-gray-700 mt-10 mb-10">
                      Inorder to make a successful request to the server,
                      authentication details are required. Secret ID and Access
                      Token is obtained from your GeePay Business dashboard
                      under API Credentials Menu.
                    </p>
                    <div className="bg-gray-950 rounded-xl py-4 px-6 w-full text-green-400 font-normal text-sm flex flex-row items-center justify-between">
                      <div>{renderJSON(credentialsData)}</div>
                      <div>
                        <CopyToClipboard
                          text={`{
    "secret_id": "xxxxxxx-xxxxxxx-xxxx-xxxx-xxxxxxxxxx",
    "access_token": "xxxxxxx-xxxxxxx-xxxx-xxxx-xxxxxxxxxx"
  }`}
                          onCopy={handleCopy}
                        >
                          <button className="relative flex flex-row items-center py-1 px-4 rounded-full bg-gray-500 space-x-1 group hover:cursor-point">
                            <div className="text-gray-400 group-hover:text-white group-hover:cursor-pointer">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                                <rect
                                  x="8"
                                  y="2"
                                  width="8"
                                  height="4"
                                  rx="1"
                                  ry="1"
                                ></rect>
                              </svg>
                            </div>
                            <div className="text-xs text-gray-400 group-hover:text-white group-hover:cursor-pointer">
                              Copy
                            </div>
                            {copied ? (
                              <p className="absolute -top-5 right-4 text-green-500 text-xs">
                                Copied!
                              </p>
                            ) : null}
                          </button>
                        </CopyToClipboard>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeMenu === 3 && (
                <div>
                  <div className="font-bold text-lg leading-loose mb-10">
                    <div className="text-md font-bold">Process Payments</div>
                    <p className="text-sm font-normal text-gray-700 mt-10 mb-10">
                      After picking your preferred client and securing your
                      request authentication credentials, you can now go ahead
                      and initiate your first payment request.
                    </p>
                    <div className="text-md font-semibold mb-4">
                      Request Body
                    </div>
                    <div>
                      <div className="w-full rounded-xl bg-gray-900">
                        <div className="w-full rounded-tl-xl rounded-tr-xl bg-gray-900 flex flex-row border-b-2 border-gray-600">
                          <div
                            className={`${
                              activeTab === 1
                                ? "border-b-2 border-green-400 text-green-400 bg-gray-900 py-2 px-6 rounded-tl-xl hover:cursor-pointer text-sm"
                                : "border-b-2 border-transparent bg-gray-900 py-2 px-6 rounded-tl-xl text-gray-400 hover:text-gray-300 hover:cursor-pointer text-sm"
                            }`}
                            onClick={() => handleTabClick(1)}
                          >
                            cURL
                          </div>
                          <div
                            className={`${
                              activeTab === 2
                                ? "border-b-2 border-green-400 text-green-400 bg-gray-900 py-2 px-6 rounded-tl-xl hover:cursor-pointer text-sm"
                                : "border-b-2 border-transparent bg-gray-900 py-2 px-6 rounded-tl-xl text-gray-400 hover:text-gray-300 hover:cursor-pointer text-sm"
                            }`}
                            onClick={() => handleTabClick(2)}
                          >
                            JavaScript
                          </div>
                          <div
                            className={`${
                              activeTab === 3
                                ? "border-b-2 border-green-400 text-green-400 bg-gray-900 py-2 px-6 rounded-tl-xl hover:cursor-pointer text-sm"
                                : "border-b-2 border-transparent bg-gray-900 py-2 px-6 rounded-tl-xl text-gray-400 hover:text-gray-300 hover:cursor-pointer text-sm"
                            }`}
                            onClick={() => handleTabClick(3)}
                          >
                            Python
                          </div>
                          <div
                            className={`${
                              activeTab === 4
                                ? "border-b-2 border-green-400 text-green-400 bg-gray-900 py-2 px-6 rounded-tl-xl hover:cursor-pointer text-sm"
                                : "border-b-2 border-transparent bg-gray-900 py-2 px-6 rounded-tl-xl text-gray-400 hover:text-gray-300 hover:cursor-pointer text-sm"
                            }`}
                            onClick={() => handleTabClick(4)}
                          >
                            PHP
                          </div>
                        </div>
                        <div className="tab-content">
                          {activeTab === 1 && (
                            <div className="">
                              <div className="">
                                <SyntaxHighlighter
                                  className="text-xs relative"
                                  language="bash"
                                  style={dracula}
                                >
                                  {curlBodyCode}
                                </SyntaxHighlighter>
                              </div>
                            </div>
                          )}
                          {activeTab === 2 && (
                            <div>
                              <div className="">
                                <SyntaxHighlighter
                                  className="text-xs relative"
                                  language="javascript"
                                  style={dracula}
                                >
                                  {jsBodyCode}
                                </SyntaxHighlighter>
                              </div>
                            </div>
                          )}
                          {activeTab === 3 && (
                            <div>
                              <div className="">
                                <SyntaxHighlighter
                                  className="text-xs relative"
                                  language="python"
                                  style={dracula}
                                >
                                  {pyBodyCode}
                                </SyntaxHighlighter>
                              </div>
                            </div>
                          )}
                          {activeTab === 4 && (
                            <div>
                              <div className="">
                                <SyntaxHighlighter
                                  className="text-xs relative"
                                  language="php"
                                  style={dracula}
                                >
                                  {phpBodyCode}
                                </SyntaxHighlighter>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="text-md font-semibold mt-8 mb-4">
                      Success Response Body - Status Code [200]
                    </div>
                    <div>
                      <div className="w-full rounded-xl bg-gray-900">
                        <div className="w-full rounded-tl-xl rounded-tr-xl bg-gray-900 flex flex-row border-b-2 border-gray-600">
                          <div
                            className={`${
                              activeTab === 1
                                ? "border-b-2 border-green-400 text-green-400 bg-gray-900 py-2 px-6 rounded-tl-xl hover:cursor-pointer text-sm"
                                : "border-b-2 border-transparent bg-gray-900 py-2 px-6 rounded-tl-xl text-gray-400 hover:text-gray-300 hover:cursor-pointer text-sm"
                            }`}
                            onClick={() => handleTabClick(1)}
                          >
                            cURL
                          </div>
                          <div
                            className={`${
                              activeTab === 2
                                ? "border-b-2 border-green-400 text-green-400 bg-gray-900 py-2 px-6 rounded-tl-xl hover:cursor-pointer text-sm"
                                : "border-b-2 border-transparent bg-gray-900 py-2 px-6 rounded-tl-xl text-gray-400 hover:text-gray-300 hover:cursor-pointer text-sm"
                            }`}
                            onClick={() => handleTabClick(2)}
                          >
                            JavaScript
                          </div>
                          <div
                            className={`${
                              activeTab === 3
                                ? "border-b-2 border-green-400 text-green-400 bg-gray-900 py-2 px-6 rounded-tl-xl hover:cursor-pointer text-sm"
                                : "border-b-2 border-transparent bg-gray-900 py-2 px-6 rounded-tl-xl text-gray-400 hover:text-gray-300 hover:cursor-pointer text-sm"
                            }`}
                            onClick={() => handleTabClick(3)}
                          >
                            Python
                          </div>
                          <div
                            className={`${
                              activeTab === 4
                                ? "border-b-2 border-green-400 text-green-400 bg-gray-900 py-2 px-6 rounded-tl-xl hover:cursor-pointer text-sm"
                                : "border-b-2 border-transparent bg-gray-900 py-2 px-6 rounded-tl-xl text-gray-400 hover:text-gray-300 hover:cursor-pointer text-sm"
                            }`}
                            onClick={() => handleTabClick(4)}
                          >
                            PHP
                          </div>
                        </div>
                        <div className="tab-content">
                          {activeTab === 1 && (
                            <div className="">
                              <SyntaxHighlighter
                                className="text-xs relative"
                                language="json"
                                style={dracula}
                              >
                                {curlResponseCode}
                              </SyntaxHighlighter>
                            </div>
                          )}
                          {activeTab === 2 && (
                            <div className="">
                              <SyntaxHighlighter
                                className="text-xs relative"
                                language="json"
                                style={dracula}
                              >
                                {curlResponseCode}
                              </SyntaxHighlighter>
                            </div>
                          )}
                          {activeTab === 3 && (
                            <div className="">
                              <SyntaxHighlighter
                                className="text-xs relative"
                                language="json"
                                style={dracula}
                              >
                                {curlResponseCode}
                              </SyntaxHighlighter>
                            </div>
                          )}
                          {activeTab === 4 && (
                            <div className="">
                              <SyntaxHighlighter
                                className="text-xs relative"
                                language="json"
                                style={dracula}
                              >
                                {curlResponseCode}
                              </SyntaxHighlighter>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiDocumentationPage;
