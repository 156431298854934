import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import DashboardNav from "../components/DashboardNav";
import SideBar from "../components/SideBar";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";

import MTNLogo from "../assets/mtn_logo.png";
import AirtelLogo from "../assets/airtel_logo.png";
import ZamtelLogo from "../assets/zamtel_logo.png";

import axios from "axios";

const InstantMobileMoneyPage = () => {
  const { user } = useSelector((state) => state.auth);
  const [grandtotal, setGrandtotal] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [timerFormLoading, setTimerFormLoading] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [description, setDescription] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentMethodLogo, setPaymentMethodLogo] = useState("");
  const [lastThreeDigits, setLastThreeDigits] = useState("");
  const [enteredPhoneNumber, setEnteredPhoneNumber] = useState("");
  const [enteredAmount, setEnteredAmount] = useState("");
  const [response, setResponse] = useState({});
  const history = useHistory();
  let [color] = useState("#ffffff");

  const [receipt_url, setReceipt_url] = useState("");
  const [business_email, setBusiness_email] = useState("");
  const [business_name, setBusiness_name] = useState("");
  const [amount, setAmount] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const [ref_number, setRef_number] = useState("");
  const [payment_channel, setPayment_channel] = useState("");
  const [id, setId] = useState("");

  const [timeLeft, setTimeLeft] = useState(60);

  const today = new Date();
  const currentDate =
    today.getDate() + "/" + (today.getMonth() + 1) + "/" + today.getFullYear();


  const override = css`
    display: block;
    margin: 0 auto;
    border-color: green;
  `;

  const handlePaymentMethod = (e) => {
    const newValue = e.target.value.replace(/[^0-9]/g, "");
    setEnteredPhoneNumber(newValue);
  };

  useEffect(() => {
    if (timerFormLoading) {
      const timer = setTimeout(() => {
        handleMobileMoneyPaymentConfirmation();
      }, 40000);
      return () => clearTimeout(timer);
    }

    if (enteredPhoneNumber.length > 1) {
      if (
        enteredPhoneNumber.startsWith("77") ||
        enteredPhoneNumber.startsWith("97")
      ) {
        setPaymentMethod("Airtel Money");
        setPaymentMethodLogo(AirtelLogo);
        if (enteredPhoneNumber.length === 9) {
          const lastThreeChars = enteredPhoneNumber.slice(-3);
          setLastThreeDigits(lastThreeChars);
        } else {
          setLastThreeDigits("");
        }
      } else if (
        enteredPhoneNumber.startsWith("76") ||
        enteredPhoneNumber.startsWith("96")
      ) {
        setPaymentMethod("MTN Money");
        setPaymentMethodLogo(MTNLogo);
        if (enteredPhoneNumber.length === 9) {
          const lastThreeChars = enteredPhoneNumber.slice(-3);
          setLastThreeDigits(lastThreeChars);
        } else {
          setLastThreeDigits("");
        }
      } else if (
        enteredPhoneNumber.startsWith("75") ||
        enteredPhoneNumber.startsWith("95")
      ) {
        setPaymentMethod("Zamtel Money");
        setPaymentMethodLogo(ZamtelLogo);
        if (enteredPhoneNumber.length === 9) {
          const lastThreeChars = enteredPhoneNumber.slice(-3);
          setLastThreeDigits(lastThreeChars);
        } else {
          setLastThreeDigits("");
        }
      } else {
        setPaymentMethod("");
        setPaymentMethodLogo();
      }
    } else {
      setPaymentMethod("");
      setPaymentMethodLogo();
    }

    if (enteredAmount.length > 0) {
      const value = parseFloat(enteredAmount);
      setEnteredAmount(value);
      setGrandtotal(value);
    } else {
      setEnteredAmount(enteredAmount);
      setGrandtotal(enteredAmount);
    }
  }, [enteredPhoneNumber, enteredAmount, timerFormLoading]);

  useEffect(() => {
    if (timerFormLoading && timeLeft > 0) {
      const timer = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [timerFormLoading, timeLeft]);

  const handleAmount = (e) => {
    //const newValue = e.target.value.replace(/[^0-9.]/g, "");
    setEnteredAmount(e.target.value);
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = e.target.scrollHeight + "px"; // Set the height based on the content
  };

  const handleDataSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    setTimeLeft(60);
    const form = e.target;
    const formData = new FormData(form);
    try {
      const response = await axios.post("/api/v1/create/instant", formData);
      if (response.status === 200) {
        //save the data to state variables
        setId(response.data.payment_id);
        setReceipt_url(response.data.receipt_url);
        setBusiness_email(response.data.business_email);
        setBusiness_name(response.data.business_name);
        setAmount(response.data.amount);
        setPhone_number(response.data.phone_number);
        setRef_number(response.data.ref_number);
        setPayment_channel(response.data.payment_channel);
      }
      setIsLoading(false);
      setTimerFormLoading(true);
      console.log(response.data);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleMobileMoneyPaymentConfirmation = async () =>{
    setIsLoading(true);
    setTimerFormLoading(false);
    const config = {
			header: {
				"Accept": "application/json"
			}
		}
		axios.post('api/v1/confirm/instant/payment/button', { id }, config)
			.then(res => {
				if(res.status === 200){
					history.push("/payments");
          			toast.success("Payment has been confirmed successfully");
				}
				setIsLoading(false);
			})
			.catch(err => {
				setIsLoading(false);
				history.push("/payments");
				toast.error("Payment has been confirmed with failed status");
			});
      
  }

  return (
    <>
      <div className="flex flex-row items-start min-h-screen">
        <div className="min-h-screen w-1/6">
          <ToastContainer />
          <SideBar />
        </div>
        <div className="min-h-screen w-5/6 bg-green-800 bg-opacity-10">
          <DashboardNav />
          <div className="w-full h-full overflow-y-auto px-10 pt-16 pb-20">
            <div className="text-md font-bold text-green-800 mb-10">
              Instant Mobile Money
            </div>
            <div className="flex flex-row items-start w-full h-full">
              {/* Request Form */}
              {isLoading ? (
                <div className="flex flex-col items-center justify-center w-full md:w-2/3 h-full">
                  <div className="m-auto">
                    <div className="flex flex-col items-center justify-center">
                      Processing...
                    </div>
                    <ClipLoader
                      color={color}
                      loading="true"
                      css={override}
                      size={70}
                    />
                  </div>
                </div>
              ) : timerFormLoading ? (
                <div className="flex flex-col items-center justify-center w-full md:w-2/3 h-full">
                  <div className="mt-20">
                    <div className='text-sm text-center text-green-80'>
                    Auto-confirms after 60 seconds. Time left: {timeLeft}{" "}
                    seconds.
                    </div>
                    
                    <button onClick={handleMobileMoneyPaymentConfirmation} className='flex flex-row items-center px-6 py-1 text-center bg-green-800 hover:bg-green-600 mt-6'>
                      Confirm Now
                    </button>
                  </div>
                </div>
              ) : (
                <div className="w-full md:w-2/3">
                  <p className="text-sm text-gray-500">
                    Create an instant mobile money payment request. Your valued
                    customer will receive a prompt on the mobile phone to
                    approve the transaction. After the payment has been
                    processed, your customer will receive a confirmation receipt
                    with their transaction details as displayed on the right
                    side of your screen. The payment reference will be generated
                    upon payment initialisation.
                  </p>

                  <form className="w-2/3 mt-16" onSubmit={handleDataSubmit}>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
                      <div className="md:col-span-2">
                        <label
                          for="small-input"
                          className="block mb-2 text-sm font-medium text-green-800 dark:text-white"
                        >
                          Customer Name (optional)
                        </label>
                        <input hidden name="owner_id" value={user.id} />
                        <input
                          onChange={(e) => setCustomerName(e.target.value)}
                          type="text"
                          id="customer_name"
                          name="customer_name"
                          value={customerName}
                          className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>
                      <div className="md:col-span-1">
                        <label
                          for="small-input"
                          className="block mb-2 text-sm font-medium text-green-800 dark:text-white"
                        >
                          Amount (ZMW)
                        </label>
                        <input
                          onChange={handleAmount}
                          value={enteredAmount}
                          type="number"
                          step="0.01"
                          id="amount"
                          name="amount"
                          className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 text-end"
                          required
                        />
                      </div>
                    </div>

                    <div className="mt-4">
                      <label
                        for="message"
                        class="block mb-2 text-sm font-medium text-green-800 dark:text-white"
                      >
                        Description
                      </label>

                      <textarea
                        value={description}
                        maxLength={160}
                        onChange={(e) => handleDescription(e)}
                        id="message"
                        name="description"
                        rows={1}
                        class="block p-2.5 w-full text-sm text-green-800 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Write payment description here..."
                      ></textarea>
                    </div>

                    <div className="mt-4">
                      <label
                        for="small-input"
                        className="block mb-2 text-sm font-medium text-green-800 dark:text-white"
                      >
                        Customer Mobile Money Number
                      </label>
                      <div class="flex relative">
                        <span class="inline-flex items-center px-3 text-sm text-white bg-green-800 border border-r-0 border-gray-300 rounded-l-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                          +260
                        </span>
                        <input
                          value={enteredPhoneNumber}
                          onChange={(e) => handlePaymentMethod(e)}
                          maxLength={9}
                          type="tel"
                          id="phone_number"
                          name="phone_number"
                          className="rounded-none rounded-r-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="phone number"
                          required
                        />
                        {paymentMethod && (
                          <div className="absolute top-2.5 right-2">
                            <div className="flex flex-col items-center justify">
                              <img
                                src={paymentMethodLogo}
                                alt="geepay airtel"
                                className="h-5"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="flex flex-row items-center space-x-1 px-4 py-1 bg-green-800 rounded text-white hover:text-green-200 mt-12"
                    >
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                        </svg>
                      </div>
                      <div>Send Payment Request</div>
                    </button>
                  </form>
                </div>
              )}

              {/* Receipt Preview */}
              <div className="w-full md:w-1/3 bg-green-800 bg-opacity-30 rounded p-8">
                <div className="flex flex-col items-center justify-center mb-5">
                  <div className="text-md text-green-800 leading-loose font-bold mb-3">
                    RECEIPT
                  </div>
                  <div className="mb-2">
                    <img
                      src={user.business_logo}
                      className="h-10"
                      alt="geepay zambia"
                    />
                  </div>
                  <div className="text-sm text-green-800 font-bold">
                    {user.business_name}
                  </div>
                  <div className="text-xs text-green-800 font-bold">
                    Cell: {user.business_phone_number}
                  </div>
                </div>

                <div className="mb-6">
                  <div className="text-xs text-green-800">
                    Date: {currentDate}
                  </div>
                  {customerName && (
                    <div className="text-xs text-green-800">
                      To : {customerName}
                    </div>
                  )}
                  {lastThreeDigits && (
                    <div className="text-xs text-green-800 inline-flex">
                      Paid via : {paymentMethod} ending with ***
                      {lastThreeDigits}
                    </div>
                  )}
                </div>
                <div>
                  <div className="flex flex-row items-center justify-between border-b-4 border-green-800 mb-4">
                    <div className="text-sm text-green-800 font-bold">
                      DESCRIPTION
                    </div>
                    <div className="text-sm text-green-800 font-bold">
                      TOTAL
                    </div>
                  </div>
                  <div className="w-full grid grid-cols-1 md:grid-cols-3">
                    <div className="col-span-2">
                      <p className="break-all text-xs text-green-800">
                        {description}
                      </p>
                    </div>
                    <div className="text-sm text-green-800 font-light col-span-1 text-end">
                      {enteredAmount.toLocaleString("en-US", {
                        style: "currency",
                        currency: "ZMW",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-between border-t-4 border-green-800 border-dotted mt-10">
                    <div className="text-sm text-green-800 font-bold">
                      Grand Total
                    </div>
                    <div className="text-sm text-green-800 font-bold">
                      {grandtotal.toLocaleString("en-US", {
                        style: "currency",
                        currency: "ZMW",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </div>
                  </div>
                </div>

                <div className="flex flex-row items-center justify-center mt-5">
                  <div className="text-xs text-center text-green-800">
                    THANK YOU
                  </div>
                </div>
                <div className="flex flex-row items-center justify-center space-x-1 mt-8">
                  <div className="text-yellow-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <rect
                        x="3"
                        y="11"
                        width="18"
                        height="11"
                        rx="2"
                        ry="2"
                      ></rect>
                      <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                    </svg>
                  </div>
                  <div className="text-yellow-500 text-sm">
                    Secured by GeePay
                  </div>
                </div>
                <div className="flex flex-row items-center justify-center mt-1">
                  <a
                    href="https://www.geepay.co.zm"
                    className="text-xs text-center text-green-800"
                  >
                    www.geepay.co.zm
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InstantMobileMoneyPage;
