import React from "react";
import Fade from "react-reveal/Fade";
import MoneyIcon from "../assets/money.png";
import ShoppingIcon from "../assets/shopping.png";
import ZescoIcon from "../assets/zesco.png";
import UssdIcon from "../assets/ussd.png";
import LinkIcon from "../assets/link.png";
import { Link } from "react-router-dom";

const ProductsMenu = () => {
  return (
    <>
      <Fade top>
        <div className="w-[320px] py-6 rounded-md shadow-md bg-green-50 z-50 absolute top-[30px] -left-8">
          <a
            href="#features"
            className="flex flex-row items-center space-x-3 w-full mb-6 hover:bg-green-100 px-6 py-3 hover:cursor-pointer"
          >
            <div className="w-10 h-10 rounded-md bg-green-900 flex items-center justify-center">
              <img src={LinkIcon} alt="gpay" className="h-5" />
            </div>
            <div>
              <div className="text-sm font-medium text-green-800 text-start">
                Merchant Payments
              </div>
              <div className="text-xs font-light text-green-800 text-start">
                POS & Payment Links
              </div>
            </div>
          </a>
          <Link
            to="/products/payment-gateway"
            className="flex flex-row items-center space-x-3 w-full mb-6 hover:bg-green-100 px-6 py-3 hover:cursor-pointer"
          >
            <div className="w-10 h-10 rounded-md bg-green-900 flex items-center justify-center">
              <img src={MoneyIcon} alt="gpay" className="h-5" />
            </div>
            <div>
              <div className="text-sm font-medium text-green-800 text-start">
                Business Gateway (API)
              </div>
              <div className="text-xs font-light text-green-800 text-start">
                Payments & Disbursements
              </div>
            </div>
          </Link>

          {/* <div className="flex flex-row items-center space-x-3 w-full mb-6 hover:bg-green-100 px-6 py-3 hover:cursor-pointer">
            <div className="w-10 h-10 rounded-md bg-green-900 flex items-center justify-center">
              <img src={UssdIcon} alt="gpay" className="h-5" />
            </div>
            <div>
              <div className="text-sm font-medium text-green-800 text-start">
                GeePay USSD
              </div>
              <div className="text-xs font-light text-green-800 text-start">
                Accept payment via merchant code
              </div>
            </div>
          </div> */}
          <a
            href="#consumer_app"
            className="flex flex-row items-center space-x-3 w-full hover:bg-green-100 px-6 py-3 hover:cursor-pointer mb-6"
          >
            <div className="w-10 h-10 rounded-md bg-green-900 flex items-center justify-center">
              <img src={ZescoIcon} alt="gpay" className="h-5" />
            </div>
            <div>
              <div className="text-sm font-medium text-green-800 text-start">
                GeePay Value Added Services
              </div>
              <div className="text-xs font-light text-green-800 text-start">
                Airtime, Bill Payments
              </div>
            </div>
          </a>
          {/* <div className="flex flex-row items-center space-x-3 w-full hover:bg-green-100 px-6 py-3 hover:cursor-pointer">
            <div className="w-10 h-10 rounded-md bg-green-900 flex items-center justify-center">
              <img src={ShoppingIcon} alt="gpay" className="h-5" />
            </div>
            <div>
              <div className="text-sm font-medium text-green-800 text-start">
                Ecommerce
              </div>
              <div className="text-xs font-light text-green-800 text-start">
                Start selling products online
              </div>
            </div>
          </div> */}
        </div>
      </Fade>
    </>
  );
};

export default ProductsMenu;
