import { useState, useEffect } from 'react'
import { LockClosedIcon, EyeIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade'
import { useDispatch, useSelector } from 'react-redux'
import { loginInitiate, setErrorEmpty } from '../redux/actions/actions'
import { ToastContainer, toast } from 'react-toastify'
import GeePayLogo from "../assets/geepay_logo.png"


const LoginPage = ({history}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  let dispatch = useDispatch();
  const { user, error, loading } = useSelector(state => state.auth);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
        history.push("/dashboard");
        console.log(user);
    }
}, [user]);

const togglePinVisiblity = () => {
  setPasswordShown(passwordShown ? false : true);
}

  const HandleLogin = (e) => {
    e.preventDefault();

    if (email && password !== "") {
      dispatch(setErrorEmpty());
      dispatch(loginInitiate(email, password));
      if (error) {
          toast.error(error);
      }
  } else {
      toast.error("Please fill provided fields");
  }

  }

  return (
      <div className="flex min-h-screen items-center justify-center bg-gradient-to-r from-green-50 to-green-100 lg:px-20 xl:px-24 3xl:px-44 pt-8 py-12 px-4 sm:px-6">
        <ToastContainer />
        <div className="w-full max-w-md space-y-8">
          <div>
          <Link to="/">
            <div className='flex flex-col justify-center items-center'>
              <img src={GeePayLogo} className="h-[70px] rounded-2xl" alt="GeePay" /> 
            </div>
          </Link>
            
            <h2 className="mt-6 text-center text-2xl font-bold tracking-tight text-green-900 mb-16">
              Sign in to your account
            </h2>
            
          </div>
          <Fade bottom>
          <form className="mt-8 space-y-6 bg-white rounded-lg p-10 border border-green-900 border-opacity-20" onSubmit={HandleLogin}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  required
                  className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="relative">
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type={passwordShown ? "text" : "password"}
                  autoComplete="current-password"
                  required
                  className="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <EyeIcon className="h-5 w-5 text-green-500 group-hover:text-indigo-400 mr-2 absolute inset-y-2 right-2" aria-hidden="true" onClick={togglePinVisiblity} />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-600"
                />
                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                  Remember me
                </label>
              </div>

              <div className="text-sm">
                <a href="#" className="font-medium text-green-600 hover:text-green-500">
                  Forgot your password?
                </a>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative flex w-full justify-center rounded-md bg-green-900 py-2 px-3 text-sm font-semibold text-white hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-800"
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <LockClosedIcon className="h-5 w-5 text-green-500 group-hover:text-green-400" aria-hidden="true" />
                </span>
                Sign in
              </button>
            </div>
          </form>
          </Fade>
          <div className='flex flex-row items-center justify-center space-x-2'>
          <div className='text-center text-sm font-medium text-green-800'>Don't have an account? </div>
          <Link to="/register" className='text-center text-sm font-medium text-green-800 hover:text-green-500'>Create Account</Link>
          </div>
          
        </div>
      </div>
  )
}

export default LoginPage