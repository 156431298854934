import { useState} from 'react'
import axios from 'axios'

const allCategoriesUrl = `api/v1/business/categories`;
const config = {
    header: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

const useGetCategories = () => {
    const [categories, setCategories] = useState([]);

    const getCategories = () => {
        axios.get(allCategoriesUrl, config).then(response => setCategories(response.data));
    }
  return {
    categories,
    getCategories
  }
}

export default useGetCategories