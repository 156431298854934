import { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import SideBar from "../components/SideBar";
import DashboardNav from "../components/DashboardNav";
import lottie from "lottie-web";
import animationData from "../assets/payments.json";
import RequestImage from "../assets/email.png";
import PaymentLink from "../assets/payment_link.png";
import InstantImage from "../assets/pos.png";
import { Link } from "react-router-dom";
import RecentPayments from "../components/RecentPayments";
import DashboardGraph from "../components/DashboardGraph";
import axios from "axios";

const DashboardPage = ({ history }) => {
  const { user } = useSelector((state) => state.auth);
  const container = useRef(null);
  const [current_payments_balance, setCurrent_payments_balance] = useState("");
  const [current_disbursement_balance, setCurrent_disbursement_balance] =
    useState("");
  const [refunds_count, setRefunds_count] = useState("");
  const [refunds_total, setRefunds_total] = useState("");
  const [payments_total, setPayments_total] = useState("");
  const [payments_total_amount, setPayments_total_amount] = useState("");

  useEffect(() => {
    getDashboardData();
    if (Object.keys(user).length > 0) {
      history.push("/dashboard");
    }
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice", // set the aspect ratio of the animation
        scaleMode: "noScale", // set the scaling mode of the animation
      },
      // Set the width and height of the container
      // to the desired size of your animation
      width: 70,
      height: 70,
    });
  }, []);

  const getDashboardData = () => {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
    };
    const nob = user.business_id;

    axios
      .get("api/v1/business/dashboard", config)
      .then((response) => {
        console.log(response.data);
        setCurrent_payments_balance(response.data.current_payments_balance);
        setCurrent_disbursement_balance(
          response.data.current_disbursement_balance
        );
        setRefunds_count(response.data.refunds_count);
        setRefunds_total(response.data.refunds_total);
        setPayments_total(response.data.payments_total);
        setPayments_total_amount(response.data.payments_total_amount);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  return (
    <>
      <div className="flex flex-row items-start h-screen">
        <div className="min-h-screen w-1/6">
          <ToastContainer />
          <SideBar />
        </div>
        <div className="min-h-screen w-5/6 bg-green-800 bg-opacity-10">
          <DashboardNav />
          <div className="w-full h-full overflow-y-auto pt-16 pb-20 px-8">
            <div className="w-full flex flex-col md:flex-row items-center md:space-x-4 space-y-8 md:space-y-0">
              <div className="w-full md:w-2/3 rounded-md bg-green-800 bg-opacity-30 p-10 h-[130px] flex flex-row items-center justify-between">
                <div>
                  <div className="text-green-800 font-bold text-lg">
                    Welcome Back,
                  </div>
                  <div className="text-green-900 text-sm">
                    You can now received payments fast & easy by creating
                    payment links
                  </div>
                </div>
                <div className="h-[80px] relative">
                  <div className="h-[80px] w-[80px] bg-green-800 absolute z-20 bg-opacity-10 rounded"></div>
                  <div className="h-[80px] w-[80px] z-10" ref={container}></div>
                </div>
              </div>
              <div className="w-full md:w-1/3 rounded-md bg-gray-50 h-[130px] shadow">
                <div className="py-2 px-5 text-xs text-green-800">
                  Quick Links
                </div>
                <div className="flex flex-row items-center justify-between space-x-6 px-10">
                  <Link
                    to="/instant/pay"
                    className="w-[90px] h-[80px] rounded border border-green-800 bg-transparent hover:cursor-pointer hover:bg-green-800 hover:bg-opacity-20 px-2 py-4 flex flex-col items-center justify-center"
                  >
                    <div>
                      <img
                        src={InstantImage}
                        alt="geepay zambia"
                        className="h-7"
                      />
                    </div>
                    <div className="text-xs text-center text-green-800 font-bold">
                      Instant
                    </div>
                    <div className="text-[10px] text-center text-green-800">
                      Mobile Money
                    </div>
                  </Link>
                  <div className="w-[100px] h-[80px] rounded border border-green-800 bg-transparent hover:cursor-pointer hover:bg-green-800 hover:bg-opacity-20 px-2 py-4 flex flex-col items-center justify-center">
                    <div>
                      <img
                        src={PaymentLink}
                        alt="geepay zambia"
                        className="h-7"
                      />
                    </div>
                    <div className="text-xs text-center text-green-800 font-bold">
                      Checkout
                    </div>
                    <div className="text-[10px] text-center text-green-800">
                      Payment Links
                    </div>
                  </div>
                  <div className="w-[110px] h-[80px] rounded border border-green-800 bg-transparent hover:cursor-pointer hover:bg-green-800 hover:bg-opacity-20 px-2 py-4 flex flex-col items-center justify-center">
                    <div>
                      <img
                        src={RequestImage}
                        alt="geepay zambia"
                        className="h-7"
                      />
                    </div>
                    <div className="text-xs text-center text-green-800 font-bold">
                      Request
                    </div>
                    <div className="text-[10px] text-center text-green-800">
                      Payment Request
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              {/* graph */}

              <section className="mt-6">
                <div className="container mx-auto">
                  <div className="flex flex-wrap -m-4">
                    <div className="w-full lg:w-1/2 p-4">
                      <div className="grid grid-cols-2 gap-3 mb-4">
                        <div className="bg-gray-50 rounded shadow py-8 px-6">
                          <div className="text-green-800 text-md font-bold">
                            ZMW {current_payments_balance}
                          </div>
                          <div className="text-green-800 text-sm">
                            Payments Current Balance
                          </div>
                          <hr className="w-[40px] mt-3" />
                        </div>
                        <div className="bg-gray-50 rounded shadow py-8 px-6">
                          <div className="text-green-800 text-md font-bold">
                            ZMW {current_disbursement_balance}
                          </div>
                          <div className="text-green-800 text-sm">
                            Disbursement Current Balance
                          </div>
                          <hr className="w-[40px] mt-3" />
                        </div>
                      </div>

                      <div className="grid grid-cols-2 gap-3">
                        <div className="bg-gray-50 rounded shadow py-8 px-6">
                          <div className="text-green-800 text-md font-bold">
                            ZMW {refunds_total}
                          </div>
                          <div className="text-green-800 text-sm">
                            Refunded Amount
                          </div>
                          <hr className="w-[40px] mt-3" />
                        </div>
                        <div className="bg-gray-50 rounded shadow py-8 px-6">
                          <div className="text-green-800 text-md font-bold">
                            {payments_total}
                          </div>
                          <div className="text-green-800 text-sm">
                            Total Payment Transactions
                          </div>
                          <hr className="w-[40px] mt-3" />
                        </div>
                      </div>
                    </div>
                    <div className="w-full lg:w-1/2 p-4">
                      <div className="bg-white rounded shadow">
                        <DashboardGraph />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div>
              <div className="">
                {/* Summary widget */}
                <div className="shadow mt-5">
                  <RecentPayments />
                </div>
              </div>

              <div></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardPage;
