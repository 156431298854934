import React, { useState } from "react";

const SamplePayCheckout = () => {
  const [paymentOption, setPaymentOption] = useState("creditCard");

  const handlePaymentOptionChange = (event) => {
    setPaymentOption(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle payment submission
  };

  return (
    <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
      <div className="relative py-3 sm:max-w-xl sm:mx-auto">
        <div className="relative px-4 py-10 bg-white mx-8 md:mx-0 shadow rounded-3xl sm:p-10">
          <div className="max-w-md mx-auto">
            <div className="flex items-center space-x-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-14 w-14 text-blue-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 0C4.485 0 0 4.485 0 10c0 5.515 4.485 10 10 10 5.515 0 10-4.485 10-10 0-5.515-4.485-10-10-10zm3.707 12.707l-1.414 1.414L10 11.414l-2.293 2.707-1.414-1.414L8.586 10l-2.293-2.707 1.414-1.414L10 8.586l2.293-2.707 1.414 1.414L11.414 10l2.293 2.707z"
                  clipRule="evenodd"
                />
              </svg>
              <h1 className="text-gray-600 font-semibold text-2xl">Payment Checkout</h1>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="divide-y divide-gray-200">
                <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                  <div className="flex flex-col">
                    <label className="leading-loose">Amount</label>
                    <input
                      type="text"
                      name="amount"
                      className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-500 rounded-lg"
                      placeholder="Enter amount"
                      required
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="leading-loose">Description</label>
                    <textarea
                      name="description"
                      className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-500 rounded-lg"
                      placeholder="Enter description"
                      required
                    ></textarea>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="paymentOption"
                      value="creditCard"
                      checked={paymentOption === "creditCard"}
                      onChange={handlePaymentOptionChange}
                      className="form-radio h-4 w-4 text-gray-600 transition duration-150 ease-in-out"
                      required
                    />
                    <label htmlFor="creditCard" className="ml-2 block text-gray-700">
                      Credit Card
                      </label>
              </div>
              <div className="flex items-center">
                <input
                  type="radio"
                  name="paymentOption"
                  value="paypal"
                  checked={paymentOption === "paypal"}
                  onChange={handlePaymentOptionChange}
                  className="form-radio h-4 w-4 text-gray-600 transition duration-150 ease-in-out"
                  required
                />
                <label htmlFor="paypal" className="ml-2 block text-gray-700">
                  PayPal
                </label>
              </div>
            </div>
            <div className="pt-4 flex items-center space-x-4">
              <button
                type="submit"
                className="bg-blue-500 flex justify-center items-center w-full text-white px-4 py-3 rounded-md focus:outline-none"
              >
                Pay Now
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
);
};             

export default SamplePayCheckout