import React from 'react';
import { NavLink } from 'react-router-dom';

const NavBottomButton = (props) => {
    return (
        <div>
            <NavLink exact={true} to={props.menuLink} activeClassName="flex flex-row items-center py-2 rounded-br-lg bg-green-800 bg-opacity-25 px-4 border-l-8 border-green-800 text-green-800" className="flex flex-row items-center py-2 rounded-br-lg hover:bg-green-800 hover:bg-opacity-50 px-4 border-l-8 border-transparent hover:border-green-900 text-green-800 hover:text-green-100">
                <div className="flex flex-row items-center text-sm font-medium">
                    <div className="p-1 rounded-lg bg-transparent bg-opacity-20 shadow-md">
                        {props.menuIcon}
                    </div>
                    <span className="ml-2">{props.menuTitle}</span>
                </div>
            </NavLink>
        </div>
    )
}

export default NavBottomButton