import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import MTNLogo from "../assets/mtn_logo.png";
import AirtelLogo from "../assets/airtel_logo.png";
import ZamtelLogo from "../assets/zamtel_logo.png";

function TransferSingleModal(props) {
	const history = useHistory();
	const [business_id, setBusiness_id] = useState(props.business_id);
	const [refundAmount, setRefundAmount] = useState(props.refundAmount);
	const [refundReason, setRefundReason] = useState("");
	const [refundModalLoading, setRefundModalLoading] = useState(false);
	const [enteredPhoneNumber, setEnteredPhoneNumber] = useState("");
	const [paymentMethod, setPaymentMethod] = useState("");
    const [paymentMethodLogo, setPaymentMethodLogo] = useState("");
	const [lastThreeDigits, setLastThreeDigits] = useState("");
	const [customerName, setCustomerName] = useState("");
    const [description, setDescription] = useState("");
    const [enteredAmount, setEnteredAmount] = useState("");
	let [color] = useState("#ffffff");
	const override = css`
    display: block;
    margin: 0 auto;
    border-color: green;
  `;

	useEffect(()=>{
		handleDecimalPlaces();
		
	});

	const handlePaymentMethod=(e)=>{
		setEnteredPhoneNumber(e.target.value);
		if (enteredPhoneNumber.length > 1) {
			if (
			  enteredPhoneNumber.startsWith("77") ||
			  enteredPhoneNumber.startsWith("97")
			) {
			  setPaymentMethod("Airtel Money");
			  setPaymentMethodLogo(AirtelLogo);
			  if (enteredPhoneNumber.length === 9) {
				const lastThreeChars = enteredPhoneNumber.slice(-3);
				setLastThreeDigits(lastThreeChars);
			  } else {
				setLastThreeDigits("");
			  }
			} else if (
			  enteredPhoneNumber.startsWith("76") ||
			  enteredPhoneNumber.startsWith("96")
			) {
			  setPaymentMethod("MTN Money");
			  setPaymentMethodLogo(MTNLogo);
			  if (enteredPhoneNumber.length === 9) {
				const lastThreeChars = enteredPhoneNumber.slice(-3);
				setLastThreeDigits(lastThreeChars);
			  } else {
				setLastThreeDigits("");
			  }
			} else if (
			  enteredPhoneNumber.startsWith("75") ||
			  enteredPhoneNumber.startsWith("95")
			) {
			  setPaymentMethod("Zamtel Money");
			  setPaymentMethodLogo(ZamtelLogo);
			  if (enteredPhoneNumber.length === 9) {
				const lastThreeChars = enteredPhoneNumber.slice(-3);
				setLastThreeDigits(lastThreeChars);
			  } else {
				setLastThreeDigits("");
			  }
			} else {
			  setPaymentMethod("");
			  setPaymentMethodLogo();
			}
		  } else {
			setPaymentMethod("");
			setPaymentMethodLogo();
		  }
	}

	const handleDecimalPlaces = () => {
        const num = parseFloat(refundAmount);
        const formattedNum = num.toFixed(2);
		setRefundAmount(formattedNum);
	}

	const handleSelectedReason = (e) => {
		setRefundReason(e.target.value);
	}

	const handleSendMobileMoney = async () => {
		setRefundModalLoading(true);
		
		const config = {
			headers: {
			  "Accept": "application/json",
			  "Content-Type": "application/json",
			},
		  };
		axios.post('api/v1/transfer/mno', { business_id, description, enteredAmount, enteredPhoneNumber, customerName, paymentMethod }, config)
			.then(res => {
				if(res.status === 200){
					if(res.data.success === true){
						history.push("/transfers");
					
          			toast.success("Payment has been sent successfully");
					}else{
						history.push("/transfers");
					
						toast.error(res.data.message);
					}
					
				}
				setRefundModalLoading(false);
				props.onClose();
				console.log(res.data);
				
			})
			.catch(err => {
				setRefundModalLoading(false);
				if(err.response.status === 400){
					toast.error(err.response.data.message);
					
				}
				props.onClose();
				console.log(err.response);
			});
	}

	const handleAmount = (e) => {
		//const newValue = e.target.value.replace(/[^0-9.]/g, "");
		setEnteredAmount(e.target.value);
	  };
    
  return (
    <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster bg-gray-900 bg-opacity-40">
		<div
			className="border border-green-800 shadow-lg modal-container bg-white w-11/12 md:max-w-md mx-auto rounded-lg z-50 overflow-y-auto">
			{ refundModalLoading ? <div>
				<div className="m-auto py-20">
                    <div className="flex flex-col items-center justify-center text-red-700">
                      Processing...
                    </div>
                    <ClipLoader
                      color={color}
                      loading="true"
                      css={override}
                      size={50}
                    />
                  </div>
			</div>:<div className="modal-content py-4 text-left px-6">
		
				<div className="flex justify-between items-center pb-3">
					<p className="text-md font-bold text-green-800">Transfer Money</p>
					<div onClick={props.onClose} className="modal-close cursor-pointer z-50">
						<svg className="fill-current text-blue-800" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
							viewBox="0 0 18 18">
							<path
								d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
							</path>
						</svg>
					</div>
				</div>
	
				<div className="my-5">
				<div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
                      <div className="md:col-span-2">
                        <label
                          for="small-input"
                          className="block mb-2 text-sm font-medium text-green-800 dark:text-white"
                        >
                          Customer Name (optional)
                        </label>
                        
                        <input
                          onChange={(e) => setCustomerName(e.target.value)}
                          type="text"
                          id="customer_name"
                          name="customer_name"
                          value={customerName}
                          className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>
                      <div className="md:col-span-1">
                        <label
                          for="small-input"
                          className="block mb-2 text-sm font-medium text-green-800 dark:text-white"
                        >
                          Amount (ZMW)
                        </label>
                        <input
                          onChange={handleAmount}
                          value={enteredAmount}
                          type="number"
                          step="0.01"
                          id="amount"
                          name="amount"
                          className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 text-end"
                          required
                        />
                      </div>
                    </div>
					<div className="mt-4">
                      <label
                        for="message"
                        class="block mb-2 text-sm font-medium text-green-800 dark:text-white"
                      >
                        Description
                      </label>

                      <textarea
                        value={description}
                        maxLength={160}
                        onChange={(e) => setDescription(e.target.value)}
                        id="message"
                        name="description"
                        rows={1}
                        class="block p-2.5 w-full text-sm text-green-800 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Write payment description here..."
                      ></textarea>
                    </div>
					<div className="mt-4">
                      <label
                        for="small-input"
                        className="block mb-2 text-sm font-medium text-green-800 dark:text-white"
                      >
                        Customer Mobile Money Number
                      </label>
                      <div class="flex relative">
                        <span class="inline-flex items-center px-3 text-sm text-white bg-green-800 border border-r-0 border-gray-300 rounded-l-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                          +260
                        </span>
                        <input
                          value={enteredPhoneNumber}
                          onChange={(e) => handlePaymentMethod(e)}
                          maxLength={9}
                          type="tel"
                          id="phone_number"
                          name="phone_number"
                          className="rounded-none rounded-r-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="phone number"
                          required
                        />
                        {paymentMethod && (
                          <div className="absolute top-2.5 right-2">
                            <div className="flex flex-col items-center justify">
                              <img
                                src={paymentMethodLogo}
                                alt="geepay airtel"
                                className="h-5"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
					<div className="flex justify-end pt-2 flex-row space-x-2 mt-4">
					
					<button onClick={handleSendMobileMoney}
						className="focus:outline-none px-6 bg-green-800 py-1 rounded-lg text-white hover:bg-green-700">Send {enteredAmount.toLocaleString("en-US", {style: "currency",currency: "ZMW",minimumFractionDigits: 2,maximumFractionDigits: 2,})}</button>
				</div>
				</div>


			</div>}
		</div>
	</div>
  );
}

export default TransferSingleModal;
