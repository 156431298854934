import React, {useState} from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import GeePayLogo from '../assets/geepay_logo_wide.png'

const FormLoadingUtil = () => {
    let [loading] = useState(true);
    let [color] = useState("#ffffff");

    const override = css`
        display: block;
        margin: 0 auto;
        border-color: green;
    `;

  return (
     <div className="h-[280px] w-full flex bg-transparent">
            <div className="m-auto">
                <div className='flex flex-col items-center justify-center'>
                    <div></div>
                </div>
                <ClipLoader color={color} loading={loading} css={override} size={30} />
            </div>
        </div>
  )
}

export default FormLoadingUtil