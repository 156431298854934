import * as types from './actionTypes';
import axios from 'axios';

//authentication actions
const loginStart = () => ({
    type: types.LOGIN_START
});

const loginSuccess = (details) => ({
    type: types.LOGIN_SUCCESS,
    payload: details
});

const loginFail = (error) => ({
    type: types.LOGIN_FAIL,
    payload: error
});

const registerStart = () => ({
    type: types.REGISTER_START
});

const registerSuccess = (details) => ({
    type: types.REGISTER_SUCCESS,
    payload: details
});

const registerFail = (error) => ({
    type: types.REGISTER_FAIL,
    payload: error
});

export const setErrorEmpty = () => ({
    type: types.SET_ERROR_EMPTY
});

export const logoutInitiate = () => ({
    type: types.LOGOUT_USER
});

export const loginInitiate = (email, password) => {
    return function (dispatch) {
        dispatch(loginStart());
        axios.get('sanctum/csrf-cookie').then(response => {
            console.log(response);
            // Login...
            const config = {
                header: {
                    "Accept": "application/json"
                }
            }
            axios.post('api/v1/login', { email, password }, config)
                .then(res => {
                    dispatch(loginSuccess(res.data));
                })
                .catch(err => {
                    dispatch(loginFail("Ooops, invalid login credentials"));
                });
        });
    }
};

export const registerInitiate = (phoneNumber, code) => {
    return function (dispatch) {
        const config = {
            header: {
                "Content-Type": "application/json"
            }
        }
        dispatch(registerStart());
        axios.patch('api/v1/register', { phoneNumber, code }, config)
            .then(res => {
                dispatch(registerSuccess(res.data));
            })
            .catch(err => {
                dispatch(registerFail("Ooops, invalid register credentials"));
            });
    }
};
