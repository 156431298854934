import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import LoaderComponent from "../components/LoaderComponent";
import GeePayLogoWhite from "../assets/geepay_logo_wide_white.png";
import GeePayLogo from "../assets/geepay_logo_wide.png";
import axios from "axios";
import FormLoadingUtil from "../components/FormLoadingUtil";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import useGetCategories from '../hooks/useGetCategories.js'

const FreelancerPage = () => {
  const [pageLoading, setPageLoading] = useState(false);
  const [name, setName] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessCategories, setBiusinessCategories] = useState([]);
  const [selectedBusinessCategory, setSelectedBiusinessCategory] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [categories, getCategories] = useGetCategories();

  let [loading] = useState(true);
  let [color] = useState("#ffffff");

    const override = css`
        display: block;
        margin: 0 auto;
        border-color: green;
    `;

  useEffect(() => {
    getCategories();
  }, []);

  const fetchCategories = async () => {
    setPageLoading(true);
    const config = {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    };
    const result = await axios.get(`api/v1/business/categories`, config);
    setBiusinessCategories(result.data.data);
    setPageLoading(false);
  };

  const togglePinVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const handlePhoneNumber = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setPhonenumber(value);
  };

  const handleBusinessCategory = (e) => {
    setSelectedBiusinessCategory(e.target.value);
    console.log(selectedBusinessCategory);
  };

  const RegisterHandler = (e) => {
    e.preventDefault();

    if (
      name &&
      businessName &&
      businessEmail &&
      selectedBusinessCategory &&
      phonenumber &&
      password
    ) {
      setPageLoading(true);
    } else {
      toast.error("Please fill provided fields");
    }
  };

  return (
    <>
        <div
          className="w-full h-screen bg-gradient-to-r from-green-50 to-green-100 grid grid-cols-1 lg:grid-cols-2"
          style={{ gridTemplateColumns: "1fr 2fr" }}
        >
          <div className="bg-green-800 lg:p-12 3xl:px-44 pt-8 hidden lg:block h-screen">
            <div className="bg-transparent lg:p-12 3xl:p-44 p-8 flex items-center justify-center">
              <ToastContainer />
              <div className="w-full h-full">
                <div className="mb-6">
                  <Link
                    to="/register"
                    className="text-gray-50 text-sm hover:text-yellow-300 hover:cursor-pointer"
                  >
                    {"<"} Back
                  </Link>
                </div>
                <Link to="/" className="text-4xl font-bold text-white">
                  <img
                    src={GeePayLogoWhite}
                    className="h-[70px] rounded-2xl"
                    alt="GeePay"
                  />
                </Link>
                <div className="text-md text-yellow-300 ml-8 mt-2">
                  Freelancer or Sole Trader Account
                </div>
              </div>
            </div>
          </div>
          <div className="bg-transparent lg:p-12 3xl:p-44 p-8 flex items-center justify-center min-h-screen overflow-y-scroll">
            <div className="w-full h-full">
            {pageLoading ? (
                <div className="h-screen w-full flex flex-col items-center justify-center">
                  <div className="text-md font-light text-gray-500 mt-20 mb-4">
                    Second things second...
                  </div>
                  <div className="text-4xl font-bold text-green-700">Let us,</div>
                  <div className="text-3xl font-bold text-green-700 mb-10">
                    Create an account
                  </div>
                  <ClipLoader color={color} loading={loading} css={override} size={38} />
                  <div className="h-32"></div>
                </div>
                ) : (<div>
              <Link
                to="/"
                className="text-4xl font-bold text-green-900 lg:hidden"
              >
                <div className="">
                  <img
                    src={GeePayLogo}
                    className="h-[70px] rounded-2xl"
                    alt="GeePay"
                  />
                </div>
              </Link>
              <div className="text-sm font-medium lg:hidden">
                FREELANCER or SOLE TRADER
              </div>
              <div className="text-md font-light text-gray-500 mt-20 mb-4">
                Second things second...
              </div>
              <div className="text-4xl font-bold text-green-700">Let us,</div>
              <div className="text-3xl font-bold text-green-700 mb-10">
                Create an account
              </div>
              
              <div>
                <div className="w-full h-full">
                  <form onSubmit={RegisterHandler}>
                    <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
                      <div>
                        <label
                          for="name"
                          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Your full name
                        </label>
                        <div class="relative">
                          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-green-800">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="w-5 h-5"
                            >
                              <path
                                fillRule="evenodd"
                                d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            placeholder="enter name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          for="businessEmail"
                          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Your Business Email
                        </label>
                        <div class="relative">
                          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-green-800">
                            <svg
                              aria-hidden="true"
                              class="w-5 h-5 text-gray-500 dark:text-gray-400"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                            </svg>
                          </div>
                          <input
                            type="email"
                            id="businessEmail"
                            name="businessEmail"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            placeholder="name@email.com"
                            value={businessEmail}
                            onChange={(e) => setBusinessEmail(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 mt-4">
                      <div>
                        <label
                          for="businessName"
                          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Your Business Name
                        </label>
                        <div class="relative">
                          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-green-800">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="w-5 h-5"
                            >
                              <path d="M19.5 21a3 3 0 003-3v-4.5a3 3 0 00-3-3h-15a3 3 0 00-3 3V18a3 3 0 003 3h15zM1.5 10.146V6a3 3 0 013-3h5.379a2.25 2.25 0 011.59.659l2.122 2.121c.14.141.331.22.53.22H19.5a3 3 0 013 3v1.146A4.483 4.483 0 0019.5 9h-15a4.483 4.483 0 00-3 1.146z" />
                            </svg>
                          </div>
                          <input
                            type="text"
                            id="businessName"
                            name="businessName"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            placeholder="enter business name"
                            value={businessName}
                            onChange={(e) => setBusinessName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          for="businessCategory"
                          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Select your Business Category
                        </label>
                        <select
                          id="businessCategory"
                          name="businessCategory"
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          onChange={(e) => handleBusinessCategory(e)}
                        >
                          <option>Select Category</option>
                          {categories.map((category) => (
                            <option
                              key={category.id}
                              value={category.id}
                              className="flex flex-row items-center space-x-2"
                            >
                              {category.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 mt-4">
                      <div>
                        <label
                          for="phonenumber"
                          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Your Business Phone Number
                        </label>
                        <div class="relative">
                          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-green-800">
                            <div className="text-md font-bold">+260</div>
                          </div>
                          <input
                            type="tel"
                            maxLength="9"
                            id="phonenumber"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full pl-16 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            placeholder="phone Number"
                            value={phonenumber}
                            onChange={handlePhoneNumber}
                            tabIndex={1}
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          for="password"
                          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Your Password
                        </label>
                        <div class="relative">
                          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-green-800">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="w-5 h-5"
                            >
                              <path
                                fillRule="evenodd"
                                d="M12 1.5a5.25 5.25 0 00-5.25 5.25v3a3 3 0 00-3 3v6.75a3 3 0 003 3h10.5a3 3 0 003-3v-6.75a3 3 0 00-3-3v-3c0-2.9-2.35-5.25-5.25-5.25zm3.75 8.25v-3a3.75 3.75 0 10-7.5 0v3h7.5z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                          <input
                            type={passwordShown ? "text" : "password"}
                            id="password"
                            name="password"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500 z-30"
                            placeholder="***********"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <div>
                            <button
                              class="absolute inset-y-0 right-3 z-50 flex items-center pl-3 pointer-events-none text-green-800 hover:cursor-pointer z-30"
                              onClick={togglePinVisiblity}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6">
                      <fieldset>
                        <legend class="sr-only">terms and conditions</legend>
                        <div class="flex items-center mb-4">
                          <input
                            id="checkbox-2"
                            type="checkbox"
                            value=""
                            required
                            class="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            for="checkbox-2"
                            class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            I agree to the{" "}
                            <Link
                              to="/terms-and-conditions"
                              href="#"
                              class="text-green-600 hover:underline dark:text-green-500"
                            >
                              terms and conditions
                            </Link>
                            .
                          </label>
                        </div>
                      </fieldset>
                    </div>
                    <button
                      type="submit"
                      className="px-5 py-2 text-center bg-green-800 text-gray-50 rounded text-sm mt-6"
                    >
                      Create Account
                    </button>
                  </form>
                </div>
              </div>
              </div>
              )}
            </div>
          </div>
        </div>
    </>
  );
};

export default FreelancerPage;
