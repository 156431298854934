import React from "react";
import { useState, useRef } from "react";
import ProductsMenu from "./ProductsMenu";
import { useOnHoverOutside } from "./../hooks/useOnHoverOutside";
import { useOnHoverOutsideDeveloperMenu } from "./../hooks/useOnHoverOutsideDeveloperMenu";
import DeveloperMenu from "./DeveloperMenu";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import GeePayLogoWide from "../assets/geepay_logo_wide.png";

const Navbar = () => {
  const { user } = useSelector((state) => state.auth);
  const dropdownProductsMenuRef = useRef(null); // Create a reference for dropdown container
  const dropdownDeveloperMenuRef = useRef(null); // Create a reference for dropdown container
  const [isProductsMenuDropDownOpen, setProductsMenuDropDownOpen] =
    useState(false);
  const [isDeveloperMenuDropDownOpen, setDeveloperMenuDropDownOpen] =
    useState(false);

  // Function to close dropdown
  const closeHoverProductsMenu = () => {
    setProductsMenuDropDownOpen(false);
  };

  // Function to close dropdown
  const closeHoverDeveloperMenu = () => {
    setDeveloperMenuDropDownOpen(false);
  };

  useOnHoverOutside(dropdownProductsMenuRef, closeHoverProductsMenu); // Call the hook
  useOnHoverOutsideDeveloperMenu(
    dropdownDeveloperMenuRef,
    closeHoverDeveloperMenu
  );

  return (
    <div className="w-full h-[60px] py-10 flex flex-row items-center justify-between px-10 bg-gradient-to-r from-green-50 to-green-100">
      <div>
        <div className="md:hidden">
          <div></div>
        </div>
        <Link to="/">
          <img
            src={GeePayLogoWide}
            className="h-[50px] xl:h-[70px] rounded-2xl"
            alt="GeePay"
          />
        </Link>
      </div>
      <div className="hidden md:flex flex-row items-center space-x-10">
        <div ref={dropdownProductsMenuRef}>
          <button
            onMouseOver={() => setProductsMenuDropDownOpen(true)}
            className="flex flex-row items-center justify-start space-x-2 text-md hover:cursor-pointer pb-2 border-b-2 border-transparent relative font-bold"
          >
            <span>Products</span>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </span>
            {isProductsMenuDropDownOpen && <ProductsMenu />}
          </button>
        </div>
        <Link
          to="/pricing"
          className="hidden lg:flex flex-row items-center space-x-2 text-md hover:cursor-pointer pb-2 border-b-2 border-transparent hover:border-green-900 font-bold"
        >
          Pricing
        </Link>
        <Link
          to="/support"
          className="hidden lg:flex flex-row items-center space-x-2 text-md hover:cursor-pointer pb-2 border-b-2 border-transparent hover:border-green-900 font-bold"
        >
          Support
        </Link>
        <div ref={dropdownDeveloperMenuRef} className="relative">
          <button
            onMouseOver={() => setDeveloperMenuDropDownOpen(true)}
            className="flex flex-row items-center space-x-2 text-md hover:cursor-pointer pb-2 border-b-2 border-transparent font-bold"
          >
            <span>Developer</span>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </span>
          </button>
          {isDeveloperMenuDropDownOpen && <DeveloperMenu />}
        </div>
      </div>
      {Object.keys(user).length > 0 ? (
        <Link
          to="/dashboard"
          className="px-6 py-2 text-sm text-gray-50 bg-green-900 border border-green-900 rounded-md font-bold hover:bg-green-700 hover:cursor-pointer"
        >
          Dashboard
        </Link>
      ) : (
        <div className="flex flex-row items-center space-x-2 xl:space-x-6">
          <a
            href="https://business.geepay.co.zm"
            className="px-6 py-2 text-sm text-green-900 bg-transparent border border-green-900 rounded-md font-bold hover:cursor-pointer"
          >
            Business Login
          </a>
          {/*  <Link
            to="/register"
            className="px-6 py-2 text-sm text-gray-50 bg-green-900 border border-green-900 rounded-md font-bold hover:bg-green-700 hover:cursor-pointer"
          >
            Create Account
          </Link> */}
        </div>
      )}
    </div>
  );
};

export default Navbar;
