import React from 'react'
import { Fade } from 'react-reveal'
import {CSVLink} from 'react-csv'
import { PDFDownloadLink,Image, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

import logo from '../assets/geepay_logo.png'; 

const DownloadRecordsMenu = (props) => {
    const { handleExcel, fetchDataPDF } = props;

    const styles = StyleSheet.create({
        page: {
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: 35,
          },
        container: {
          flexDirection: 'row',
          borderBottom: '1 solid black',
          alignItems: 'center',
          height: 24,
          textAlign: 'center',
          fontStyle: 'bold',
        },
        cell: {
          width: '20%',
          fontSize: 11,
        },
        reference: {
          width: '30%',
          fontSize: 11,
        },
        logo: {
            width: 50,
            height: 50,
            marginRight: 'auto',
            marginLeft: 'auto',
          },
          header: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            borderBottomWidth: 1,
            borderBottomColor: '#bfbfbf',
            marginBottom: 10,
          },
          headerText: {
            fontSize: 12,
            marginBottom: 5,
          },
      });

    const PdfDocument = () => {
        return (
          <View>
            <View style={styles.container}>
              <Text style={[styles.reference]}>Payment Reference Number</Text>
              <Text style={[styles.cell]}>Payment Channel</Text>
              <Text style={[styles.cell]}>TXN Number</Text>
              <Text style={[styles.cell]}>Phone Number</Text>
              <Text style={[styles.cell]}>Description</Text>
              <Text style={[styles.cell]}>Received Amount</Text>
              <Text style={[styles.cell]}>Status</Text>
              <Text style={[styles.cell]}>Updated At</Text>
            </View>
            {fetchDataPDF.map((item) => (
            <View key={item.id} style={styles.container}>
            <Text style={[styles.cell]}>{item.payment_reference_number}</Text>
            <Text style={[styles.cell]}>{item.payment_channel}</Text>
            <Text style={[styles.cell]}>{item.txn_number}</Text>
            <Text style={[styles.cell]}>{item.phone_number}</Text>
            <Text style={[styles.cell]}>{item.description}</Text>
            <Text style={[styles.cell]}>{item.received_amount}</Text>
            <Text style={[styles.cell]}>{item.status}</Text>
            <Text style={[styles.cell]}>{item.updated_at}</Text>
            </View>
      ))}
    </View>
        );
            }


    const MyDocument = () => {
        return (
          <Document>
            <Page orientation='landscape' size="A4" style={styles.page}>
                <View style={styles.header}>
                    <Image src={logo} style={styles.logo} />
                </View>
                <PdfDocument/>
            </Page>
        </Document>
        );
      }

  return (
    <>
    <Fade top>
            <div className='bg-white shadow w-[120px] p-2 rounded absolute top-6 z-50'>
                
            <PDFDownloadLink document={<MyDocument />} fileName="customer_payments.pdf">
          {({ blob, url, loading, error }) =>
            loading ? '' : <div className='rounded flex flex-row items-center space-x-1 text-sm text-green-800 hover:cursor-pointer hover:bg-green-800 hover:bg-opacity-20'>
            <div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-3 h-3">
                <path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0016.5 9h-1.875a1.875 1.875 0 01-1.875-1.875V5.25A3.75 3.75 0 009 1.5H5.625z" />
                <path d="M12.971 1.816A5.23 5.23 0 0114.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 013.434 1.279 9.768 9.768 0 00-6.963-6.963z" />
                </svg>
            </div>
            <div>PDF</div>
        </div>
          }
        </PDFDownloadLink>
                <CSVLink data={handleExcel}>
                <div className='rounded flex flex-row items-center space-x-1 text-sm text-green-800 hover:cursor-pointer hover:bg-green-800 hover:bg-opacity-20'>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-3 h-3">
                            <path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0016.5 9h-1.875a1.875 1.875 0 01-1.875-1.875V5.25A3.75 3.75 0 009 1.5H5.625z" />
                            <path d="M12.971 1.816A5.23 5.23 0 0114.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 013.434 1.279 9.768 9.768 0 00-6.963-6.963z" />
                            </svg>
                        </div>
                    <div>Excel</div>
                </div>
                </CSVLink>
                
            </div>
            </Fade>
    </>
  )
}

export default DownloadRecordsMenu