import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import SearchEngine from "./SearchEngine";

const DashboardNav = ({history}) => {
  const { user } = useSelector((state) => state.auth);

  return (
    <div className='h-[80px] w-full py-4 sticky flex flex-row items-center justify-between px-8'>
       <div>
        <SearchEngine/>
       </div>
       <div className="flex flex-row items-center space-x-6">
        <div className="rounded bg-green-800 bg-opacity-20 text-green-800 p-1 w-10 h-10 flex items-center justify-center relative">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5" />
</svg>
          <span className="h-6 rounded-full text-xs text-white bg-red-800 absolute z-50 -top-2 -right-2 py-1 px-2">3</span>
        </div>
       <div className='flex flex-row items-center space-x-2'>
          <div>
            <div className="text-end font-semibold text-sm text-gray-600">{user.name}</div>
            <div className="text-end font-light text-xs text-green-800">{user.business_name}</div>
          </div>
          <div className='h-10 w-10 rounded-full border border-green-800 border-opacity-35'>
            <img src={user.avatar} className='h-10 w-10 rounded-full object-cover' alt='gpay'/>
          </div>
       </div>
       </div>
    </div>
  )
}

export default DashboardNav