import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function RefundModal(props) {
	const history = useHistory();
	const [id, setId] = useState(props.id);
	const [refundAmount, setRefundAmount] = useState(props.refundAmount);
	const [refundReason, setRefundReason] = useState("");
	const [refundModalLoading, setRefundModalLoading] = useState(false);
	let [color] = useState("#ffffff");
	const override = css`
    display: block;
    margin: 0 auto;
    border-color: green;
  `;

	useEffect(()=>{
		handleDecimalPlaces();
	});

	const handleDecimalPlaces = () => {
        const num = parseFloat(refundAmount);
        const formattedNum = num.toFixed(2);
		setRefundAmount(formattedNum);
	}

	const handleSelectedReason = (e) => {
		setRefundReason(e.target.value);
	}

	const handleSendRefund = async () => {
		setRefundModalLoading(true);
		
		const config = {
			header: {
				"Accept": "application/json"
			}
		}
		axios.post('api/v1/refund/payment', { id, refundReason }, config)
			.then(res => {
				if(res.status === 200){
					console.log(res.data);
					history.push("/payments");
					
          			toast.success("Payment has been refunded successfully");
				}
				setRefundModalLoading(false);
				props.onClose();
				
			})
			.catch(err => {
				setRefundModalLoading(false);
				if(err.response.status === 400){
					history.push("/payments");
					toast.error("Oops, something went wrong. Try again later");
				}
				props.onClose();
			});
	}
    
  return (
    <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster bg-gray-900 bg-opacity-40">
		<div
			className="border border-green-800 shadow-lg modal-container bg-white w-11/12 md:max-w-md mx-auto rounded-lg z-50 overflow-y-auto">
			{ refundModalLoading ? <div>
				<div className="m-auto py-20">
                    <div className="flex flex-col items-center justify-center text-red-700">
                      Processing...
                    </div>
                    <ClipLoader
                      color={color}
                      loading="true"
                      css={override}
                      size={50}
                    />
                  </div>
			</div>:<div className="modal-content py-4 text-left px-6">
		
				<div className="flex justify-between items-center pb-3">
					<p className="text-md font-bold text-blue-800">Refund a payment</p>
					<div onClick={props.onClose} className="modal-close cursor-pointer z-50">
						<svg className="fill-current text-blue-800" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
							viewBox="0 0 18 18">
							<path
								d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
							</path>
						</svg>
					</div>
				</div>
	
				<div className="my-5">
					<p className='text-sm text-green-800'>Are you sure you want to refund of ZMW {refundAmount} to {props.refundCustomer} via {props.refundPaymentMethod}?</p>
					<div className='mt-4 flex flex-row items-center justify-between space-x-2'>
						<label className='text-sm font-bold text-green-800'>Reason</label>
						<select onChange={(e)=>handleSelectedReason(e)} name='reason' className='rounded text-sm text-green-800'>
							<option value="Duplicate Transaction">Duplicate Transaction</option>
							<option value="I dont recognise the customer">I don't recognise the customer</option>
							<option value="Failure to deliver service/ product">Failure to deliver service/ product</option>
							<option value="Mutual resolution">Mutual dispute resolution</option>
						</select>
					</div>
				</div>

				<div className="flex justify-end pt-2 flex-row space-x-2">
					<button onClick={props.onClose}
						className="focus:outline-none modal-close px-4 bg-gray-400 py-1 rounded-lg text-green-800 hover:bg-gray-300">Cancel</button>
					<button onClick={handleSendRefund}
						className="focus:outline-none px-4 bg-blue-500 py-1 rounded-lg text-white hover:bg-blue-700">Confirm</button>
				</div>
			</div>}
		</div>
	</div>
  );
}

export default RefundModal;
