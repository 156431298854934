import React, {useState} from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import GeePayLogo from '../assets/geepay_logo_wide.png'

const LoaderComponent = () => {
    let [loading] = useState(true);
    let [color] = useState("#ffffff");

    const override = css`
        display: block;
        margin: 0 auto;
        border-color: green;
    `;


    return (
        <div className="h-screen w-full flex bg-green-800 bg-opacity-10">
            <div className="m-auto">
                <div className='flex flex-col items-center justify-center'>
                    <img src={GeePayLogo} className='h-10' alt='GeePay Logo'/>
                </div>
                <ClipLoader color={color} loading={loading} css={override} size={70} />
            </div>
        </div>
    )
}

export default LoaderComponent