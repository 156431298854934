import React, { useState } from 'react';
import axios from 'axios';

function SearchEngine() {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setQuery(value);

    axios.get(`/api/search?q=${value}`)
      .then(response => setResults(response.data))
      .catch(error => console.error(error));
  }

  return (
    <div className='w-full'>
        <div class="pt-2 relative mx-auto text-gray-600 w-[500px]">
        <input class="border-2 border-gray-300 bg-white h-10 px-5 rounded-full text-sm focus:outline-none w-full"
          type="search" name="search" placeholder="search engine..." value={query} onChange={handleInputChange}/>
      </div>

      {results.map(result => (
        <div key={result.id}>
          <h2>{result.title}</h2>
          <p>{result.description}</p>
        </div>
      ))}
    </div>
  );
}

export default SearchEngine;
