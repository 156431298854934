import React from "react";
import Navbar from "../components/Navbar";
import b2C from "../assets/b2c.png";
import business from "../assets/business.png";
import Security from "../assets/security.png";
import { FooterBottom } from "../components/FooterBottom";
import Footer from "../components/Footer";
import Fade from "react-reveal/Fade";

const PaymentGatewayProduct = () => {
  return (
    <div>
      <Navbar />
      <div className="w-full min-h-screen bg-gradient-to-r from-green-50 to-green-100">
        <div className="h-[600px] w-full bg-gradient-to-r from-green-700 to-green-900">
          <div className="w-full h-[600px] flex flex-col items-center justify-center">
            <div className="py-2 px-6 bg-gray-200 text-green-900 rounded-full mb-8">
              Payment Gateway
            </div>
            <div className="text-3xl font-semibold text-gray-100 mb-10">
              Quick. Easy. Reliable.
            </div>
            <p className="text-md text-gray-100 mb-10">
              Give your customers the payment experience they deserve by
              integrating Squad’s seamless and secure gateway.
            </p>
            <a
              href="https://business.geepay.co.zm"
              className="px-6 py-2 text-sm text-gray-100 bg-transparent border border-gray-100 rounded-md font-bold hover:cursor-pointer"
            >
              Business Login
            </a>
          </div>
        </div>
        <div className="pt-20 w-full">
          <div className="text-green-900 font-semibold text-3xl text-center">
            Accept payments on any device through any channel
          </div>
        </div>
        <section class="text-gray-600 body-font">
          <div className="container px-5 py-24 mx-auto">
            <div className="flex flex-wrap -m-4">
              <div className="p-4 md:w-1/3">
                <Fade left>
                  <div className="h-full border-2 border-green-900 border-opacity-20 rounded-lg overflow-hidden">
                    <img
                      className="lg:h-[270px] md:h-36 w-full object-cover object-center"
                      src={b2C}
                      alt="GeePay"
                    />
                    <div className="p-6">
                      <h1 className="title-font text-lg font-medium text-green-900 mb-3">
                        Business to Customer Transactions
                      </h1>
                      <p className="leading-relaxed mb-3 text-gray-600">
                        GeePay provides a seamless and convenient payment
                        experience for customers, supporting a wide range of
                        payment methods including credit cards, debit cards,
                        digital wallets, and alternative payment options. With
                        features like one-click checkout, customers can complete
                        purchases quickly and easily, reducing friction and
                        increasing conversion rates. GeePay's support for
                        recurring billing enables merchants to set up
                        subscription-based services or automatic payments,
                        offering customers a hassle-free way to make regular
                        payments for ongoing products or services. Moreover,
                        GeePay ensures that the payment experience is optimized
                        for mobile devices, catering to the growing segment of
                        mobile consumers and enabling merchants to capture more
                        sales opportunities across different platforms.
                      </p>
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="p-4 md:w-1/3">
                <Fade top>
                  <div className="h-full border-2 border-green-900 border-opacity-20 rounded-lg overflow-hidden">
                    <img
                      className="lg:h-[270px] md:h-36 w-full object-cover object-center"
                      src={business}
                      alt="GeePay"
                    />
                    <div className="p-6">
                      <h1 className="title-font text-lg font-medium text-green-900 mb-3">
                        Manage Online Payments
                      </h1>
                      <p className="leading-relaxed mb-3 text-gray-600">
                        GeePay offers merchants a comprehensive suite of tools
                        and features to efficiently manage their online
                        payments. With real-time transaction processing
                        capabilities, merchants can receive payments instantly,
                        enabling prompt order fulfillment and enhancing the
                        overall customer experience. GeePay also simplifies
                        accounting tasks with automated payment reconciliation,
                        which matches incoming payments with corresponding
                        orders or invoices, minimizing manual effort and
                        ensuring accurate financial records. Additionally,
                        merchants benefit from detailed reporting
                        functionalities provided by GeePay, gaining valuable
                        insights into their transaction history, sales trends,
                        and revenue analysis, which inform strategic business
                        decisions and drive growth.
                      </p>
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="p-4 md:w-1/3">
                <Fade right>
                  <div className="h-full border-2 border-green-900 border-opacity-20 rounded-lg overflow-hidden">
                    <img
                      className="lg:h-[270px] md:h-36 w-full object-cover object-center"
                      src={Security}
                      alt="GeePay"
                    />
                    <div className="p-6">
                      <h1 className="title-font text-lg font-medium text-green-900 mb-3">
                        Secure Transactions
                      </h1>
                      <p className="leading-relaxed mb-3 text-gray-600">
                        GeePay employs state-of-the-art encryption techniques
                        and adheres to industry-standard security protocols like
                        PCI DSS to ensure that all transactions processed
                        through its platform are securely encrypted and
                        transmitted. By prioritizing the security of sensitive
                        data such as credit card information and personal
                        details, GeePay instills trust and confidence in both
                        merchants and customers, reducing the risk of fraud and
                        identity theft associated with online transactions.
                      </p>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
      <FooterBottom />
    </div>
  );
};

export default PaymentGatewayProduct;
