import React, {useState} from 'react'
import { ToastContainer } from 'react-toastify';
import AllPayments from '../components/AllPayments';
import DashboardNav from '../components/DashboardNav';
import FailedPatments from '../components/FailedPatments';
import PendingPayments from '../components/PendingPayments';
import RefundedPayments from '../components/RefundedPayments';

import SideBar from '../components/SideBar';
import SuccessfulPayments from '../components/SuccessfulPayments';
import CreatePaymentModal from '../components/CreatePaymentModal';



const PaymentsPage = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [showCreatePaymentModal, setShowCreatePaymentModal] = useState(false);
  

  function handleTabClick(tabIndex) {
    setActiveTab(tabIndex);
  }

  const tabs = [
    { label: 'All', index: 2, component: <AllPayments/>},
    { label: 'Successful', index: 1, component: <SuccessfulPayments/>},
    { label: 'Pending', index: 3, component: <PendingPayments/>},
    { label: 'Failed', index: 4, component: <FailedPatments/> },
    { label: 'Refunded', index: 5, component: <RefundedPayments/> }
  ];

  const handleCreatePayment = () =>{
    //show generate
    setShowCreatePaymentModal(true);
  }

  const handleCreatePaymentModalClose = () => {
    setShowCreatePaymentModal(false);
  }
  

  return (
    <>
      <div className='flex flex-row items-start h-screen'>
      {showCreatePaymentModal && <CreatePaymentModal onClose={handleCreatePaymentModalClose} />}
      <div className='h-screen w-1/6'>
      <ToastContainer />
      <SideBar/>
      </div>
      <div className='h-screen w-5/6 bg-green-800 bg-opacity-10'>
        <DashboardNav/>
        <div className='w-full h-screen overflow-y-auto p-8'>
        <div className='text-green-900 font-medium text-lg mb-8 flex flex-row items-center justify-between'>
          <div>Payments</div>
          <div></div>
        </div>
        <div className='flex flex-row items-end justify-between'>
          <div className='bg-green-800 px-4 py-1 text-white hover:text-green-200 flex flex-row items-center space-x-1 rounded shadow hover:cursor-pointer'>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5">
                      <path fill-rule="evenodd" d="M12 3.75a.75.75 0 01.75.75v6.75h6.75a.75.75 0 010 1.5h-6.75v6.75a.75.75 0 01-1.5 0v-6.75H4.5a.75.75 0 010-1.5h6.75V4.5a.75.75 0 01.75-.75z" clip-rule="evenodd" />
                    </svg>
                </span>
                <span className='text-sm' onClick={() => handleCreatePayment()}>Create Payment</span>
            </div>
            <div className='flex flex-row items-end'>
            {tabs.map(tab => (
              <div
                key={tab.index}
                className={`text-md rounded-tl rounded-tr px-3 bg-gray-200 hover:cursor-pointer text-green-800 hover:text-green-900 hover:bg-green-800 hover:bg-opacity-20 border border-green-800 border-opacity-20 ${tab.index === activeTab ? 'text-md bg-green-800 bg-opacity-40 rounded-tl rounded-tr border border-green-800 border-opacity-40' : ''}`}
                onClick={() => handleTabClick(tab.index)}
              >
                {tab.label}
              </div>
            ))}
            </div>
        </div>
        
      <div className='mt-10 sticky'>{tabs.find(tab => tab.index === activeTab)?.component}</div>
        </div>
       
        </div>
      </div> 
    </>
  )
}

export default PaymentsPage;