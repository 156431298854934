import React from 'react';
import InstantImage from '../assets/pos.png'
import RequestImage from '../assets/email.png'
import PaymentLink from '../assets/payment_link.png'
import { Link } from 'react-router-dom'

function CreatePaymentModal(props) {

  return (
    <div className="main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster bg-gray-900 bg-opacity-30">
		<div
			className="border border-green-800 shadow-lg modal-container bg-white w-11/12 md:max-w-md mx-auto rounded-lg z-50 overflow-y-auto">
			<div className="modal-content py-4 text-left px-6">
		
				<div className="flex justify-between items-center pb-3">
					<p className="text-md font-bold text-green-800">Create Payment</p>
					<div onClick={props.onClose} className="modal-close cursor-pointer z-50">
						<svg className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
							viewBox="0 0 18 18">
							<path
								d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
							</path>
						</svg>
					</div>
				</div>
	
				<div className="my-5">
					<div className='flex flex-row items-center justify-center space-x-6'>
                        <Link to="/instant/pay" className='w-[90px] h-[80px] rounded border border-green-800 bg-transparent hover:cursor-pointer hover:bg-green-800 hover:bg-opacity-20 px-2 py-4 flex flex-col items-center justify-center'>
                            <div>
                                <img src={InstantImage} alt='geepay zambia' className='h-7'/>
                            </div>
                            <div className='text-xs text-center text-green-800 font-bold'>Instant</div>
                            <div className='text-[10px] text-center text-green-800'>Mobile Money</div>
                        </Link>
                        <div className='w-[100px] h-[80px] rounded border border-green-800 bg-transparent hover:cursor-pointer hover:bg-green-800 hover:bg-opacity-20 px-2 py-4 flex flex-col items-center justify-center'>
                            <div>
                                <img src={PaymentLink} alt='geepay zambia' className='h-7'/>
                            </div>
                            <div className='text-xs text-center text-green-800 font-bold'>Checkout</div>
                            <div className='text-[10px] text-center text-green-800'>Payment Links</div>
                        </div>
                        <div className='w-[110px] h-[80px] rounded border border-green-800 bg-transparent hover:cursor-pointer hover:bg-green-800 hover:bg-opacity-20 px-2 py-4 flex flex-col items-center justify-center'>
                            <div>
                                <img src={RequestImage} alt='geepay zambia' className='h-7'/>
                            </div>
                            <div className='text-xs text-center text-green-800 font-bold'>Request</div>
                            <div className='text-[10px] text-center text-green-800'>Payment Request</div>
                        </div>
                    </div>
				</div>

				<div className='h-4'></div>
			</div>
		</div>
	</div>
  );
}

export default CreatePaymentModal;
