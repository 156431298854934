import React, { useEffect } from 'react';

const DownloadMobileApp = () => {
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Check if the user agent contains 'Android' or 'iOS'
    if (/android/i.test(userAgent)) {
      window.location.href = 'https://play.google.com/store/apps';
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = 'https://apps.apple.com/';
    }
  }, []);

  return (
    <div>
      <h1>Redirecting...</h1>
      {/* Add your content or a loading indicator here */}
    </div>
  );
};

export default DownloadMobileApp;
