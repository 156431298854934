import React,{useState, useEffect} from 'react'
import { ToastContainer } from 'react-toastify'
import DashboardNav from '../components/DashboardNav'
import SideBar from '../components/SideBar'
import axios from "axios";
import { useSelector } from "react-redux";
import TransferSingleModal from '../components/TransferSingleModal';
import SuccessfulTransfers from '../components/SuccessfulTransfers';

const TransfersPage = () => {
  const { user } = useSelector((state) => state.auth);
  const [disbursement_balance, setDisbursement_balance] = useState("********");
  const [total_disbursed, setTotal_disbursed] = useState("********");
  const [business_id, setBusiness_id] = useState(0);
  const [showTransferSingleModal, setShowTransferSingleModal] = useState(false);

  useEffect(()=>{
    getCurrentDisbursementBalance();
  });

  const getCurrentDisbursementBalance=()=>{
    setBusiness_id(user.business_id);
    const config = {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    };
		axios.post('api/v1/business/disbursement/balance', { business_id }, config)
			.then(res => {
				if(res.status === 200){
					setDisbursement_balance(res.data.current_balance);
          setTotal_disbursed(res.data.total_disbursed);
				}
				
				
			})
			.catch(err => {
				setDisbursement_balance("");
        console.log(err.response.data);
			});
  }

  const handleTransferSingleModalClose = () => {
    getCurrentDisbursementBalance();
    setShowTransferSingleModal(false);
    
  }
  
  const handleShowTransferSingleModal=()=>{
    setBusiness_id(user.business_id);
    setShowTransferSingleModal(true);
  }
  return (
    <>
      <div className='flex flex-row items-start min-h-screen'>
      {showTransferSingleModal && <TransferSingleModal business_id={business_id} onClose={handleTransferSingleModalClose} />}
      <div className='min-h-screen w-1/6'>
      <ToastContainer />
      <SideBar/>
      </div>
      <div className='min-h-screen w-5/6 bg-green-800 bg-opacity-10'>
        <DashboardNav/>
        <div className='w-full h-full overflow-y-auto p-8'>
          <div className='mt-4'></div>
        <div className='py-8 px-6 flex flex-row items-center justify-between border border-green-800 border-opacity-10 rounded'>
          <div className='px-4 py-1 bg-green-800 text-white rounded hover:bg-green-600 hover:cursor-pointer' onClick={handleShowTransferSingleModal}>Transfer Money</div>
         <div className='flex flex-col items-end'>
         <div className='font-bold text-sm text-green-800'>Current Balance {" "}ZMW {" "}
          {disbursement_balance.toLocaleString("en-US", {
                        style: "currency",
                        currency: "ZMW",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
          </div>
          <div className='font-bold text-sm text-green-800'>Total Amount Disbursed {" "}ZMW {" "}
          {total_disbursed.toLocaleString("en-US", {
                        style: "currency",
                        currency: "ZMW",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
          </div>
         </div>
        </div>



        <SuccessfulTransfers/>
        </div>
      </div> 
      </div>
    </>
  )
}

export default TransfersPage