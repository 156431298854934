import { Link } from "react-router-dom";
import GeePayLogoFooter from "../assets/geepay_logo_wide_white.png";

const Footer = () => {
  return (
    <>
      <footer
        className="wow fadeInUp relative z-10 bg-green-900 pt-16 md:pt-20 lg:pt-24 mx-auto flex flex-col items-center justify-center"
        data-wow-delay=".1s"
      >
        <div className="container">
          <div className="w-full flex flex-wrap">
            <div className="w-full px-4 md:w-1/2 lg:w-4/12 xl:w-5/12">
              <div className="mb-12 max-w-[360px] lg:mb-16">
                <Link to="/" className="mb-8 inline-block">
                  <img
                    src={GeePayLogoFooter}
                    alt="logo"
                    className="w-full h-12"
                    width={140}
                    height={30}
                  />
                  <img
                    src={GeePayLogoFooter}
                    alt="logo"
                    className="hidden w-full dark:block"
                    width={140}
                    height={30}
                  />
                </Link>
                <p className="mb-9 text-base font-medium leading-relaxed text-gray-100">
                  Whether making international payments, receiving funds,
                  managing your digital business, or accessing capital, GeePay
                  account opens businesses & individuals up to the world of
                  seamless transaction.
                </p>
              </div>
            </div>

            <div className="w-full px-4 sm:w-1/2 md:w-1/2 lg:w-2/12 xl:w-2/12"></div>

            <div className="w-full px-4 sm:w-1/2 md:w-1/2 lg:w-2/12 xl:w-2/12">
              <div className="mb-12 lg:mb-16">
                <h2 className="mb-10 text-xl font-bold text-gray-100">
                  Useful Links
                </h2>
                <ul>
                  <li>
                    <a
                      href="/terms-and-conditions"
                      className="mb-4 inline-block text-base font-medium text-gray-100 hover:text-primary"
                    >
                      {" "}
                      Terms of Use{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      href="/privacy-policy"
                      className="mb-4 inline-block text-base font-medium text-gray-100 hover:text-primary"
                    >
                      {" "}
                      Privacy Policy{" "}
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="/refund-policy"
                      className="mb-4 inline-block text-base font-medium text-gray-100 hover:text-primary"
                    >
                      {" "}
                      Refund Policy{" "}
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>

            <div className="w-full px-4 md:w-1/2 lg:w-4/12 xl:w-3/12">
              <div className="mb-12 lg:mb-16">
                <h2 className="mb-10 text-xl font-bold text-gray-100">
                  Support & Help
                </h2>
                <ul>
                  <li>
                    <a
                      href="/support"
                      className="mb-4 inline-block text-base font-medium text-gray-100 hover:text-primary"
                    >
                      {" "}
                      Get Support{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
