import React from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import HeroGal from "../assets/HeroSvg.svg"
import ClassImage from "../assets/class.jpg"
import GalsImage from "../assets/girls.jpg"
import SaloonImage from "../assets/saloon.jpg"
import GeePayLogoWhite from "../assets/geepay_logo_wide_white.png"

const RegisterPage = () => {
  return (
    <div className="w-full h-[1000px] bg-gradient-to-r from-green-50 to-green-100 grid grid-cols-1 lg:grid-cols-2">
      <div className="bg-green-800 lg:px-12 xl:px-24 3xl:px-44 pt-8 hidden md:block">
        <div className="bg-transparent lg:p-20 p-8 flex items-center justify-center">
          <div className="w-full h-full">
            <div className="mb-6">
              <Link
                to="/"
                className="text-gray-50 text-sm hover:text-yellow-300 hover:cursor-pointer"
              >
                {"<"} Home
              </Link>
            </div>
            <div>
              <Link to="/" className="text-4xl font-bold text-white">
              <div className=''>
              <img src={GeePayLogoWhite} className="h-[70px] rounded-2xl" alt="GeePay" /> 
            </div>
              </Link>
            </div>
            <div className="relative mt-10">
              <div className="hidden xl:block absolute top-[130px] left-20 z-20">
                <img src={HeroGal} className="h-[300px] rounded-2xl" alt="GPay" />
              </div>
              <div className="hidden 2xl:block rounded-xl w-[300px] h-[150px] bg-white absolute -top-2 right-10 z-30 shadow p-2">
              <div className="rounded-xl w-full h-full border border-green-200 p-3">
                <div className="text-sm font-light text-green-900 mb-4">
                  Active payment requests
                </div>
                <div className="text-[10px] text-green-900 mb-2">
                  You requested ZMW400k for disbursement
                </div>
                <div className="flex flex-row items-center space-x-2">
                  <div className="flex flex-row items-center">
                    <div className="h-6 relative">
                      <div className="h-6 w-6 rounded-full border border-gray-200 bg-green-100 absolute">
                        <img src={SaloonImage} className="h-6 w-6 rounded-full object-cover" alt="gpay_zambia"/>
                      </div>
                      <div className="h-6 w-6 rounded-full border border-gray-200 bg-green-100 absolute left-2">
                        <img src={GalsImage} className="h-6 w-6 rounded-full object-cover" alt="gpay_zambia"/>
                      </div>
                      <div className="h-6 w-6 rounded-full border border-gray-200 bg-green-100 absolute left-5">
                        <img src={ClassImage} className="h-6 w-6 rounded-full object-cover" alt="gpay_zambia"/>
                      </div>
                    </div>
                  </div>
                  <div className="w-10"></div>
                  <div className="w-2 h-2 rounded-full bg-yellow-300 bg-opacity-50"></div>
                  <div className="text-[9px]">283k transactions</div>
                  <div className="w-2 h-2 rounded-full bg-yellow-300 bg-opacity-50"></div>
                  <div className="w-2"></div>
                </div>
                <div className="flex flex-row items-center mt-3">
                  <div className="h-1 w-1/2 bg-green-500"></div>
                  <div className="h-1 w-full bg-yellow-300"></div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-transparent lg:p-12 xl:p-24 3xl:p-44 p-8 flex items-center justify-center">
        <div className="w-full h-full lg:p-10">
          <Link to="/" className="text-4xl font-bold text-green-900 md:hidden">
            GPAY LOGO
          </Link>
          <div className="text-md font-light text-gray-500 mt-20 mb-4">
            First things first...
          </div>
          <div className="text-4xl font-bold text-green-700">Tell us,</div>
          <div className="text-3xl font-bold text-green-700 mb-20">
            What is your business type?
          </div>
          <Fade right>
            <Link
              to="/register/freelancer"
              className="w-full py-3 px-6 flex flex-row items-center justify-between rounded-lg border border-green-900 border-opacity-25 hover:border-green-900 hover:bg-green-800 hover:cursor-pointer mb-6 text-green-800 hover:text-yellow-300"
            >
              <div className="flex flex-row items-center space-x-6">
                <div className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                    />
                  </svg>
                </div>
                <div className="text-m font-medium">
                  Freelancer or Sole Trader
                </div>
              </div>
              <div className="font-bold text-md">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
</svg>

                </div>
            </Link>
            <Link
              to="/register/company"
              className="w-full py-3 px-6 flex flex-row items-center justify-between rounded-lg border border-green-900 border-opacity-25 hover:border-green-900 hover:bg-green-800 hover:cursor-pointer mb-6 text-green-800 hover:text-yellow-300"
            >
              <div className="flex flex-row items-center space-x-6">
                <div className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
                    />
                  </svg>
                </div>
                <div className="text-md font-medium">
                  Private Limited or Public Company
                </div>
              </div>
              <div className="font-bold text-md">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
</svg>
              </div>
            </Link>
            <Link
              to="/register/ngo"
              className="w-full py-3 px-6 flex flex-row items-center justify-between rounded-lg border border-green-900 border-opacity-25 hover:border-green-900 hover:bg-green-800 hover:cursor-pointer mb-6 text-green-800 hover:text-yellow-300"
            >
              <div className="flex flex-row items-center space-x-6">
                <div className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
                    />
                  </svg>
                </div>
                <div className="text-md font-medium">
                  N.G.O or Society
                </div>
              </div>
              <div className="font-bold text-md">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
</svg>
              </div>
            </Link>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
