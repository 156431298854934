import React from "react";
import Navbar from "../components/Navbar";
import { FooterBottom } from "../components/FooterBottom";
import Footer from "../components/Footer";
import Fade from "react-reveal/Fade";

const PricingPage = () => {
  return (
    <div>
      <Navbar />
      <div className="h-[300px] w-full bg-gradient-to-r from-green-700 to-green-900">
        <div className="w-full h-[300px] flex flex-col items-center justify-center">
          <div className="py-2 px-6 bg-gray-200 text-green-900 rounded-full mb-8">
            Our Pricing
          </div>

          <p className="max-w-2xl mx-auto text-md text-center text-gray-100 mb-10">
            We serve you with convenient competitive pricing for various
            transactions
          </p>
        </div>
      </div>
      <div className="pt-20 w-full">
        <div className="text-green-900 font-semibold text-3xl text-center mb-6">
          Our Pricing Structure
        </div>
        <div>
          <div className="max-w-3xl xl:max-w-4xl mx-auto">
            <div className="text-md font-bold text-green-900 mb-10">
              Wallet to Wallet
            </div>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-green-50">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Transaction Amount Range
                  </th>
                  <th scope="col" className="px-6 py-3 text-end">
                    Charge
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    1 - 150
                  </th>
                  <td className="px-6 py-4 text-end">0.18</td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    151 - 300
                  </th>
                  <td className="px-6 py-4 text-end">0.35</td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    301 - 500
                  </th>
                  <td className="px-6 py-4 text-end">0.60</td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    501 - 1000
                  </th>
                  <td className="px-6 py-4 text-end">1.00</td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    1001 - 3000
                  </th>
                  <td className="px-6 py-4 text-end">1.80</td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    3001 - 5000
                  </th>
                  <td className="px-6 py-4 text-end">2.50</td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    5001 - 10000
                  </th>
                  <td className="px-6 py-4 text-end">3.50</td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Above 10000
                  </th>
                  <td className="px-6 py-4 text-end">6.80</td>
                </tr>
              </tbody>
            </table>
            <div className="text-md font-bold text-green-900 mt-10 mb-10">
              Wallet to MNO/Other Wallets
            </div>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-green-50">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Transaction Amount Range
                  </th>
                  <th scope="col" className="px-6 py-3 text-end">
                    Charge
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    1 - 150
                  </th>
                  <td className="px-6 py-4 text-end">2.08</td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    151 - 300
                  </th>
                  <td className="px-6 py-4 text-end">3.10</td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    301 - 500
                  </th>
                  <td className="px-6 py-4 text-end">6.20</td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    501 - 1000
                  </th>
                  <td className="px-6 py-4 text-end">10.50</td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    1001 - 3000
                  </th>
                  <td className="px-6 py-4 text-end">20.08</td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    3001 - 5000
                  </th>
                  <td className="px-6 py-4 text-end">31.00</td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    5001 - 10000
                  </th>
                  <td className="px-6 py-4 text-end">51.50</td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Above 10000
                  </th>
                  <td className="px-6 py-4 text-end">101.80</td>
                </tr>
              </tbody>
            </table>
            <div className="text-md font-bold text-green-900 mt-10 mb-10">
              Wallet to Bank
            </div>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mb-20">
              <thead className="text-xs text-gray-700 uppercase bg-green-50">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Transaction Amount Range
                  </th>
                  <th scope="col" className="px-6 py-3 text-end">
                    Charge
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    1 - 150
                  </th>
                  <td className="px-6 py-4 text-end">6.08</td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    151 - 300
                  </th>
                  <td className="px-6 py-4 text-end">6.10</td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    301 - 500
                  </th>
                  <td className="px-6 py-4 text-end">6.20</td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    501 - 1000
                  </th>
                  <td className="px-6 py-4 text-end">10.50</td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    1001 - 3000
                  </th>
                  <td className="px-6 py-4 text-end">20.08</td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    3001 - 5000
                  </th>
                  <td className="px-6 py-4 text-end">31.00</td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    5001 - 10000
                  </th>
                  <td className="px-6 py-4 text-end">51.50</td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Above 10000
                  </th>
                  <td className="px-6 py-4 text-end">101.80</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Footer />
      <FooterBottom />
    </div>
  );
};

export default PricingPage;
