import React from "react";
import Navbar from "../components/Navbar";
import { FooterBottom } from "../components/FooterBottom";
import Footer from "../components/Footer";
import Fade from "react-reveal/Fade";

const SupportPage = () => {
  return (
    <div>
      <Navbar />
      <div className="w-full min-h-screen bg-gradient-to-r from-green-50 to-green-100">
        <div className="h-[300px] w-full bg-gradient-to-r from-green-700 to-green-900">
          <div className="w-full h-[300px] flex flex-col items-center justify-center">
            <div className="py-2 px-6 bg-gray-200 text-green-900 rounded-full mb-8">
              Help & support
            </div>

            <p className="max-w-2xl mx-auto text-md text-center text-gray-100 mb-10">
              Goodfellow Digital Limited is committed to providing the highest
              standard of service to our clients. In line with Bank of Zambia
              directive below are the guidelines to be followed when lodging a
              complaint.
            </p>
          </div>
        </div>
        <div className="pt-20 w-full">
          <div className="text-green-900 font-semibold text-3xl text-center mb-6">
            Complaints Handling Procedure
          </div>
        </div>
        <div>
          <div className="max-w-3xl lg:max-w-4xl mx-auto p-4">
            <div className="mt-4 text-gray-800">
              <ul className="mb-4">
                <Fade left>
                  <li className="rounded shadow shadow-green-500 py-6 px-8 mb-12 bg-gray-50 relative text-gray-700 text-sm">
                    Complaints can be submitted through various channels,
                    including in person, phone or email. Our Customer Service
                    Officers (CSO) are responsible for receiving and documenting
                    complaints promptly via Mail (customerservices@geepay.co.zm)
                    and phone (+260974605814).
                    <div className="absolute -top-6 left-6 text-3xl font-bold text-white bg-green-900 h-10 w-10 rounded-full flex items-center justify-center">
                      1
                    </div>
                  </li>
                </Fade>
                <Fade right>
                  <li className="rounded shadow shadow-green-500 py-6 px-8 mb-12 bg-gray-50 relative text-gray-700 text-sm">
                    Your complaint will be acknowledged in writing within two
                    (2) working days.
                    <div className="absolute -top-6 left-6 text-3xl font-bold text-white bg-green-900 h-10 w-10 rounded-full flex items-center justify-center">
                      2
                    </div>
                  </li>
                </Fade>
                <Fade left>
                  <li className="rounded shadow shadow-green-500 py-6 px-8 mb-12 bg-gray-50 relative text-gray-700 text-sm">
                    Your complaint will be investigated competently, promptly
                    and impartially to understand the facts, identify any gaps
                    or issues, and determine the root cause.
                    <div className="absolute -top-6 left-6 text-3xl font-bold text-white bg-green-900 h-10 w-10 rounded-full flex items-center justify-center">
                      3
                    </div>
                  </li>
                </Fade>
                <Fade right>
                  <li className="rounded shadow shadow-green-500 py-6 px-8 mb-12 bg-gray-50 relative text-gray-700 text-sm">
                    Simple complaints will be resolved within five (5) working
                    days.
                    <div className="absolute -top-6 left-6 text-3xl font-bold text-white bg-green-900 h-10 w-10 rounded-full flex items-center justify-center">
                      4
                    </div>
                  </li>
                </Fade>
                <Fade left>
                  <li className="rounded shadow shadow-green-500 py-6 px-8 mb-12 bg-gray-50 relative text-gray-700 text-sm">
                    Complex complaints involving other service providers will be
                    resolved within fifteen (15) working days
                    <div className="absolute -top-6 left-6 text-3xl font-bold text-white bg-green-900 h-10 w-10 rounded-full flex items-center justify-center">
                      5
                    </div>
                  </li>
                </Fade>
                <Fade right>
                  <li className="rounded shadow shadow-green-500 py-6 px-8 mb-12 bg-gray-50 relative text-gray-700 text-sm">
                    Response shall be given to the client within forty eight
                    (48) hours after conlusion of the investigation, response
                    shall be given in writing.
                    <div className="absolute -top-6 left-6 text-3xl font-bold text-white bg-green-900 h-10 w-10 rounded-full flex items-center justify-center">
                      6
                    </div>
                  </li>
                </Fade>
              </ul>

              <p className="mb-4 text-sm text-gray-700">
                In the event that you are not satisfied with the resolution, you
                may appeal to the CEO highlighting the specific reasons why you
                remain dissatisfied.
              </p>

              <p className="text-sm text-gray-700 mb-20">
                Furthermore, in the event that you remain unsatisfied, you may
                appeal to Bank of Zambia (BOZ), Bankers Association of Zambia
                (BAZ), consumer protection Authority (CPA), financial
                ombudsperson or a court of compete authority.
              </p>
            </div>
          </div>
        </div>
        <Footer />
        <FooterBottom />
      </div>
    </div>
  );
};

export default SupportPage;
