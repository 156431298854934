import React from 'react'
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import GeePayLogoWhite from "../assets/geepay_logo_white.png";
import GeePayLogo from "../assets/geepay_logo.png"

const CompanyPage = () => {
  return (
    <div className="w-full h-[1000px] bg-gradient-to-r from-green-50 to-green-100 grid grid-cols-1 lg:grid-cols-2">
    <div className="bg-green-800 lg:px-20 xl:px-24 3xl:px-44 pt-8 hidden md:block">
      <div className="bg-transparent lg:p-20 xl:p-24 3xl:p-44 p-8 flex items-center justify-center">
        <div className="w-full h-full p-10">
        <div className="mb-6">
              <Link
                to="/register"
                className="text-gray-50 text-sm hover:text-green-400 hover:cursor-pointer"
              >
                {"<"} Back
              </Link>
            </div>
          <Link to="/" className="text-4xl font-bold text-white">
          <img src={GeePayLogoWhite} className="h-[70px] rounded-2xl" alt="GeePay" />  
          </Link>
          <div className="text-md text-green-600">Private or Public Company</div>
        </div>
      </div>
    </div>
    <div className="bg-transparent lg:p-20 xl:p-24 3xl:p-44 p-8 flex items-center justify-center">
      <div className="w-full h-full px-10">
        <Link to="/" className="text-4xl font-bold text-green-900 md:hidden">
        <div className=''>
              <img src={GeePayLogo} className="h-[70px] rounded-2xl" alt="GeePay" /> 
            </div>
        </Link>
        <div className="text-md font-light text-gray-500 mt-20 mb-4">
          Second things second...
        </div>
        <div className="text-4xl font-bold text-green-700">Let us,</div>
        <div className="text-3xl font-bold text-green-700 mb-20">
          Create an account
        </div>
        <Fade right>
          <div></div>
        </Fade>
      </div>
    </div>
  </div>
  )
}

export default CompanyPage